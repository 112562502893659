import axios from "axios";
import Header from "../../dashboard/Header";
import { useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import { useToast } from "../../../components/Toast";
import {
  GET_FORMAT_NAME,
  PUT_FORMAT_STATUS,
} from "../../../utils/api-details/ApiList";

interface FormatItem {
  INVOICE_FORMAT_ID: number;
  FORMAT_NAME: string;
  SUPPORTED_OUTPUT_TYPES: string;
  FIELDS: string;
  isActive: string;
  status: string;
}

const FormatDetails = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [formatList, setFormatList] = useState<FormatItem[]>([]);
  const { showToast } = useToast();
  const get_format_name = GET_FORMAT_NAME;
  const put_format_status = PUT_FORMAT_STATUS;

  useEffect(() => {
    getFormatDetails();
  }, []);

  const getFormatDetails = () => {
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + get_format_name}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const results = response?.data?.data?.data?.results;
        const processedResults = results.map((item: FormatItem) => ({
          INVOICE_FORMAT_ID: item.INVOICE_FORMAT_ID,
          FORMAT_NAME: item.FORMAT_NAME,
          SUPPORTED_OUTPUT_TYPES: item.SUPPORTED_OUTPUT_TYPES,
          FIELDS: JSON.parse(item.FIELDS).fields.join(", "),
          isActive: item.status,
          status:item.status,
        }));
        setFormatList(processedResults);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  };
  const handleActivateDeactivate = (formatName: string, status: string) => {
    axios.post(`${apiUrl + put_format_status}`, "", {
        params: {
          org_id: parseInt(orgID ?? ""),
          user_id: parseInt(userID ?? ""),
          format_name: formatName,
          status: status === "ACTIVE" ? false : true,
        },
      })
      .then((response) => {
        showToast("Status updated successfully", "success");
        getFormatDetails();
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
        showToast("Error while updating the status", "error");
      });
  };

  const columns = [
    {
      name: "Invoice Format ID",
      selector: (row: FormatItem) => row.INVOICE_FORMAT_ID,
      sortable: true,
      filterable: true,
    },
    {
      name: "Format Name",
      selector: (row: FormatItem) => row.FORMAT_NAME,
      sortable: true,
      filterable: true,
    },
    {
      name: "Supported Output Types",
      selector: (row: FormatItem) => row.SUPPORTED_OUTPUT_TYPES,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: FormatItem) => (
        <button
          onClick={() => handleActivateDeactivate(row.FORMAT_NAME, row.status)}
          className={`text-white rounded-lg py-2 px-4 transition-colors ${
            row.isActive === "ACTIVE" ? "bg-red-600 text-white" : "bg-blue-600"
          }`}
        >
          {row.isActive === "ACTIVE" ? "Deactivate" : "Activate"}
        </button>
      ),
    },
  ];

  return (
    <div>
      <Header />
      <div className="px-4 pb-8">
        <TableComponent
          title="Format Details"
          columns={columns}
          rows={formatList}
        />
      </div>
    </div>
  );
};

export default FormatDetails;
