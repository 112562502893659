import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Carousel from "../../components/Carousel";
import SupportedInvoice from "./SupportedInvoice";
import TakeDemo from "./TakeDemo";
import productIcon from "./../../assets/productIcon.svg";
import { useToast } from "../../components/Toast";
import axios from "axios";
import { useVisitCount } from "../../contexts/VisitCountContext";
import { GET_INVOICE_COUNT } from "../../utils/api-details/ApiList";

const Home = () => {
  const navigate = useNavigate();
  const [isDemo, setIsDemo] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [count, setCount] = useState(0);
  const { showToast } = useToast();
  // const visitCount = localStorage.getItem("visitCount");
  const visitCount = useVisitCount();
  const get_invoice_count = GET_INVOICE_COUNT;

  useEffect(() => {
    getInvoiceCount();
  }, []);

  const getInvoiceCount = () => {
    axios
      .get(`${apiUrl + get_invoice_count}`, {})
      .then((response) => {
        // console.log("response", response?.data?.data?.data?.results[0]?.Invoice_count);
        setCount(response?.data?.data?.data?.results[0]?.Invoice_count);
      })
      .catch((error) => {
        console.log(error);
        showToast("Something went wrong!", "error");
      });
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleDemo = () => {
    setIsDemo(true);
  };

  const handleCloseDemo = () => {
    setIsDemo(false);
  };

  return (
    <div className="min-h-screen p-4">
      <div className="flex flex-wrap -mx-4">
        <div className=" p-1 mb-2 w-full md:w-1/2 lg:w-1/3">
          <div className="bg-white shadow-lg rounded-lg p-4 mb-4 card border-2">
            <div className="flex items-center space-x-4">
              <img
                src={productIcon}
                alt="Invoicify"
                className="w-36 max-w-full"
              />
            </div>
          </div>
        </div>
        <div className=" p-1 mb-2 w-full  md:w-1/4 lg:w-1/6">
          <div className="bg-blue-700  shadow-lg rounded-lg p-4 mb-4 ">
            <div className="flex items-center space-x-4 justify-end">
              <h1 className="text-xl font-bold font-Inter  text-white">
                {visitCount}:
              </h1>
              <span className="text-white text-sm">TOTAL VISITS</span>
            </div>
          </div>
        </div>

        <div className=" p-1 mb-2 w-full md:w-1/4 lg:w-1/6">
          <div className="bg-blue-700  shadow-lg text-white rounded-lg p-4 mb-4">
            <div className="flex  space-x-4 justify-start">
              <span className="text-sm mt-1 ">INVOICES PROCESSED</span>
              <span className="text-xl font-bold">:</span>
              {/* <h1 className="text-xl font-bold font-Inter text-center">110</h1> */}
              <h1 className="text-xl font-bold font-Inter text-center">
                {count}{" "}
              </h1>
            </div>
          </div>
        </div>

        <div className="p-1 mb-2 w-full md:w-1/2 lg:w-1/3">
          <div className="bg-white shadow-lg rounded-lg p-2 mb-4 card border-2">
            <div className="flex flex-col items-center ">
              <div className="flex space-x-4">
                <button
                  type="button"
                  onClick={handleDemo}
                  className="px-4 py-2 w-36 bg-blue-700 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                >
                  Take a Demo
                </button>
                <button
                  type="button"
                  onClick={handleLogin}
                  className="px-4 py-2 w-36 border border-blue-700 text-blue-700 rounded-lg  flex items-center justify-center"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-100 w-full  h-80 shadow-lg rounded-lg p-6 mb-2 card">
        <Carousel />
      </div>

      <div className="mt-4">
        <SupportedInvoice isIconClick={false} />
      </div>
      {isDemo && <TakeDemo isOpen={true} onClose={handleCloseDemo} />}
    </div>
  );
};

export default Home;
