import { useCallback } from "react";
import axios from "axios";
import {
  DOWNLOAD_CSV,
  DOWNLOAD_JSON,
  DOWNLOAD_PDF,
  DOWNLOAD_XML,
} from "../utils/api-details/ApiList";

/* Download CSV File */
export const useCsvFileDownloader = (apiUrl?: string, format_name?: string) => {
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const download_csv = DOWNLOAD_CSV;

  const downloadFile = useCallback(
    async (
      uploadedFileName?: string,
      selectedOption?: string,
      selectedVersion?: string,
      latestVersion?: number,
      invoiceType?: string
    ) => {
      const fileName = uploadedFileName?.split(".")[0] + ".csv";
      const type =
        selectedOption === "original_csv" || invoiceType === "original"
          ? "original"
          : "appended";
      const version = selectedVersion !== "" || latestVersion;
      const response = await axios.get(`${apiUrl + download_csv}`, {
        params: {
          filename: fileName,
          format_name: format_name,
          version: version ? version : 0,
          // version:selectedVersion === "" ? 0 : selectedVersion,
          type: type,
          orgid: orgID,
          user_id: userID,
        },
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition?.split("filename=")[1]?.replace(/"/g, "")
        : fileName;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
    [apiUrl, format_name, orgID, userID]
  );

  return downloadFile;
};

export const Download = (data: any, filename: string) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

/* Download XMl File */
export const downloadXmlFile = async (
  apiUrl?: string,
  fileName?: string,
  type?: string,
  format_name?: string,
  selectedVersion?: string,
  latestVersion?: number
) => {
  const download_xml = DOWNLOAD_XML;
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const version = selectedVersion !== "" || latestVersion;
  const response = await axios.get(`${apiUrl + download_xml}/${fileName}`, {
    params: {
      format_name: format_name,
      version: version ? version : 0,
      // version:selectedVersion === "" ? 0 : selectedVersion,
      type: type,
      orgid: orgID,
      user_id: userID,
    },
    responseType: "blob",
  });

  if (response.headers["content-type"] === "application/xml") {
    const file = new Blob([response.data], { type: "application/xml" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = response?.headers["content-disposition"]
      ? response?.headers["content-disposition"]
          ?.split("filename=")[1]
          .replace(/['"]/g, "")
      : fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(fileURL);
  } else {
    console.error("Unexpected content type:", response.headers["content-type"]);
  }
};

/* Download Pdf File */
export const downloadPDF = async (
  apiUrl?: string,
  uploadedFileName?: string,
  format_name?: string
) => {
  const download_pdf = DOWNLOAD_PDF;
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const fileName = uploadedFileName?.split(".")[0] + ".pdf";
  const response = await axios.get(`${apiUrl + download_pdf}`, {
    responseType: "blob",
    params: {
      filename: fileName,
      format_name: format_name,
      version: 0,
      orgid: orgID,
      user_id: userID,
    },
  });
  if (response.headers["content-type"] === "application/pdf") {
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    console.log(
      "response.headers['content-disposition']",
      response.headers["content-disposition"]
    );
    link.download = response.headers["content-disposition"]
      ? response.headers["content-disposition"]
          ?.split("filename=")[1]
          ?.replace(/['"]/g, "")
      : fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(fileURL);
  } else {
    console.error("Unexpected content type:", response.headers["content-type"]);
  }
};

/* Download Json File */
export const downloadJSON = async (
  apiUrl?: string,
  uploadedFileName?: string,
  selectedOption?: string,
  format_name?: string,
  selectedVersion?: string,
  latestVersion?: number,
  invoiceType?: string
) => {
  const download_json = DOWNLOAD_JSON;
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const fileName = uploadedFileName?.split(".")[0] + ".json";
  const version = selectedVersion || latestVersion;
  if (selectedOption === "original_json" || invoiceType === "original") {
    await axios
      .get(`${apiUrl + download_json}`, {
        params: {
          filename: fileName,
          format_name: format_name,
          version: version ? version : 0,
          type: "original",
          orgid: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        Download(response.data, fileName);
      })
      .catch(function (error) {
        console.log(error);
      });
  } else if (
    selectedOption === "appended_json" ||
    selectedOption === "" ||
    invoiceType === "appended"
  ) {
    await axios
      .get(`${apiUrl + download_json}`, {
        params: {
          filename: fileName,
          format_name: format_name,
          version: version ? version : 0,
          type: "appended",
          orgid: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        Download(response.data, fileName);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
};
