import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import HistoryTable from "./HistoryTable";
import axios from "axios";
import { useToast } from "../../components/Toast";
import { INVOICE_VERSIONS } from "../../utils/api-details/ApiList";

const History = ({
  open,
  closeHistoryModal,
}: {
  open: boolean;
  closeHistoryModal: () => void;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [invoiceData, setInvoiceData] = useState([]);
  const { showToast } = useToast();
  const invoice_versions = INVOICE_VERSIONS;

  useEffect(() => {
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + invoice_versions}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const results = response.data?.data?.data?.results;
        setInvoiceData(results);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
        showToast("Something went wrong", "error");
      });
  }, [apiUrl, orgID, userID]);

  return (
    <Modal
      isOpen={open}
      onClose={closeHistoryModal}
      title="History"
      showCancelButton={false}
      size="w-7/12"
    >
      <div
        className="max-h-96 overflow-y-auto overflow-x-auto"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "#3b82f6 #f1f1f1",
        }}
      >
        <HistoryTable invoiceData={invoiceData} />
      </div>
    </Modal>
  );
};

export default History;
