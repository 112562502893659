import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useToast } from "../../../../components/Toast";
import {
  RESOLVE_NAME_DICTIONARY,
  SETUP_LOOKUP_DETAILS,
  UPDATE_LOOKUP_DETAILS,
  UPDATE_LOOKUP_DICT,
} from "../../../../utils/api-details/ApiList";

interface LookupDetails {
  url?: string;
  method?: string;
  username?: string;
  password?: string;
  requestBody?: {};
  pathParam?: {};
}

interface BuyerSupplierData {
  supplierName?: string;
  buyerName?: string;
}

const BuyerLookupDetails = ({
  selectedSetup,
  buyerLookupDetails,
  setBuyerLookupDetails = () => {},
  setDictionaryObject,
  dictionaryObject,
  formatName,
  selectedLevel,
  supportedOutput,
  buyerSupplier,
  isEdit,
  buyerDictionaryObject,
  setBuyerDictionaryObject,
}: {
  selectedSetup: string;
  buyerLookupDetails: LookupDetails;
  setBuyerLookupDetails?: (details: LookupDetails) => void;
  setDictionaryObject: (val: string) => void;
  dictionaryObject?: string;
  formatName?: string;
  selectedLevel?: string;
  supportedOutput: string[];
  buyerSupplier?: BuyerSupplierData;
  isEdit?: boolean;
  buyerDictionaryObject?: string;
  setBuyerDictionaryObject: (val: string) => void;
}) => {
  const { register, watch, setValue } = useForm<LookupDetails>({
    defaultValues: buyerLookupDetails,
  });
  const formData = watch();
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_API_URL;
  const { showToast } = useToast();
  const resolve_name_dic = RESOLVE_NAME_DICTIONARY;
  const setup_Lookup_details = SETUP_LOOKUP_DETAILS;
  // const [isConfirm, setIsConfirm] = useState(false)
  const update_Lookup_details = UPDATE_LOOKUP_DETAILS;
  const update_lookup_dict = UPDATE_LOOKUP_DICT;

  useEffect(() => {
    if (isEdit && buyerLookupDetails) {
      Object.keys(buyerLookupDetails).forEach((key) => {
        setValue(
          key as keyof LookupDetails,
          buyerLookupDetails[key as keyof LookupDetails]
        );
      });
    }
  }, [buyerLookupDetails, isEdit, setValue]);

  const handleLookupDetails = () => {
    const formatType = supportedOutput.join(",");
    if (selectedSetup === "dictionary") {
      const param = {
        org_id: orgID,
        format_name: formatName,
        output_format_types: formatType,
        save_level: selectedLevel,
        // type: "buyer",
      };
      if (isEdit) {
        axios
          .patch(`${apiUrl + update_lookup_dict}`, buyerDictionaryObject, {
            headers: {
              "Content-Type": "application/json",
            },
            params: param,
          })
          .then((response) => {
            console.log("response", response);
            showToast("Dictionary Setup Done", "success");
          })
          .catch(function (error) {
            console.log(error);
            showToast("Something went wrong", "error");
          });
      } else {
        axios
          .post(`${apiUrl + resolve_name_dic}`, buyerDictionaryObject, {
            headers: {
              "Content-Type": "application/json",
            },
            params: param,
          })
          .then((response) => {
            console.log("response", response);
            showToast("Dictionary Setup Done", "success");
          })
          .catch(function (error) {
            console.log(error);
            showToast("Something went wrong", "error");
          });
      }
    }

    const payload = {
      // field: buyerSupplier?.buyerName,
      // field: "supplier_name",
      field: "buyer_name",
      isLookupEnabled: "True",
      lookupType: selectedSetup === "Api" ? "API" : "Dictionary",
      ...(selectedSetup === "Api" && {
        apiSetup: {
          url: formData?.url,
          method: formData?.method,
          requestBody: formData?.requestBody,
          request_parameters: {
            org_id: orgID,
            user_id: userID,
          },
          pathParam: formData?.pathParam,
          credentials: {
            username: formData?.username,
            password: formData?.password,
          },
          returnType: "JSON",
        },
      }),
    };

    const param = {
      format_type: formatType,
      format_name: formatName,
      file_prefix: "buyer",
      org_id: orgID,
      user_id: userID,
      save_level: selectedLevel,
    };
    //  const api = isEdit ? update_Lookup_details :setup_Lookup_details  ;
    // if (isEdit) {
    //   axios
    //     .patch(`${apiUrl + update_Lookup_details}`, payload, {
    //       params: param,
    //     })
    //     .then((response) => {
    //       console.log("response", response);
    //       showToast("API Setup Done", "success");
    //     })
    //     .catch(function (error) {
    //       console.log("error", error);
    //       showToast("Something went wrong", "error");
    //     });
    // } else {
    //   axios
    //     .post(`${apiUrl + setup_Lookup_details}`, payload, {
    //       params: param,
    //     })
    //     .then((response) => {
    //       console.log("response", response);
    //       showToast("API Setup Done", "success");
    //     })
    //     .catch(function (error) {
    //       console.log("error", error);
    //       showToast("Something went wrong", "error");
    //     });
    // }



    if (isEdit) {
      axios
        .patch(`${apiUrl + update_Lookup_details}`, payload, {
          params: param,
        })
        .then((response) => {
          console.log("response", response);
          showToast("API Setup Done", "success");
        })
        .catch(function (error) {
          console.log("error", error);
    
            if (error?.response?.data && error?.response?.data?.detail.includes("File 'buyer_name_field_setup.json' not found")) {
              isEdit = false; 
              axios
                .post(`${apiUrl + setup_Lookup_details}`, payload, {
                  params: param,
                })
                .then((response) => {
                  console.log("response", response);
                  showToast("API Setup Done", "success");
                })
                .catch(function (error) {
                  console.log("error", error);
                  showToast("Something went wrong", "error");
                });
            } else {
              showToast("Something went wrong", "error");
            }
        });
    } else {
      axios
        .post(`${apiUrl + setup_Lookup_details}`, payload, {
          params: param,
        })
        .then((response) => {
          console.log("response", response);
          showToast("API Setup Done", "success");
        })
        .catch(function (error) {
          console.log("error", error);
          showToast("Something went wrong", "error");
        });
    }
    
  };

  useEffect(() => {
    if (buyerDictionaryObject) {
      if (typeof buyerDictionaryObject === "object") {
        setBuyerDictionaryObject(
          JSON.stringify(buyerDictionaryObject, null, 2)
        );
      }
    }
  }, [buyerDictionaryObject]);

  // Function to handle changes in the text area
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBuyerDictionaryObject(e.target.value);
  };

  return (
    <div>
      {selectedSetup === "dictionary" ? (
        <div className="p-4">
          <h3 className="font-Inter mb-4">
            Please add your dictionary data for one-time setup.
          </h3>
          <div>
            <textarea
              className="shadow appearance-none border rounded w-full h-56 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              id="request"
              placeholder="Enter dictionary data"
              value={buyerDictionaryObject || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      ) : (
        <div className="p-4">
          <h3 className="font-Inter mb-4 mt-2">
            Enter the Get API details for lookup:
          </h3>
          <form className="space-y-6">
            <div className="flex flex-col space-y-4">
              <div>
                <label
                  htmlFor="url"
                  className="block text-gray-700 font-medium mb-2"
                >
                  URL
                </label>
                <input
                  id="url"
                  type="text"
                  placeholder="Enter URL"
                  className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...register("url", { required: true })}
                />
              </div>
              <div>
                <label
                  htmlFor="method"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Method
                </label>
                <input
                  id="method"
                  type="text"
                  placeholder="Enter Methods (Get, Post, Patch, Put..)"
                  className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...register("method")}
                />
              </div>
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label
                    htmlFor="username"
                    className="block text-gray-700 font-medium mb-2"
                  >
                    Username
                  </label>
                  <input
                    id="username"
                    type="text"
                    placeholder="Enter username"
                    className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    {...register("username")}
                  />
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="password"
                    className="block text-gray-700 font-medium mb-2"
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    type="password"
                    placeholder="Enter password"
                    className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    {...register("password")}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="requestBody"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Request Body
                </label>
                <input
                  id="requestBody"
                  type="text"
                  placeholder="Enter Request body {}"
                  className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...register("requestBody")}
                />
              </div>
              <div>
                <label
                  htmlFor="pathParam"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Path Param
                </label>
                <input
                  id="pathParam"
                  type="text"
                  placeholder="Enter Path Param"
                  className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...register("pathParam")}
                />
              </div>
            </div>
          </form>
        </div>
      )}
      <div className="p-4 flex justify-end">
        <button
          onClick={handleLookupDetails}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-600"
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default BuyerLookupDetails;
