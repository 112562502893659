import { IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";

const AddFieldsFromFile = ({
  supportedOutput,
  files,
  setFiles,
  isToggledForHeader,
  setCsvFiles,
  csvFiles,
  setJsonFiles,
  jsonFiles,
  setXmlFiles,
  xmlFiles,
  setCsvFields,
  setJsonFields,
  setXmlFields,
  setFieldsList
}: {
  supportedOutput: string[];
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  isToggledForHeader: boolean;
  setCsvFiles: React.Dispatch<React.SetStateAction<File[]>>;
  csvFiles: File[];
  setJsonFiles: React.Dispatch<React.SetStateAction<File[]>>;
  jsonFiles: File[];
  setXmlFiles: React.Dispatch<React.SetStateAction<File[]>>;
  xmlFiles: File[];
  setCsvFields: React.Dispatch<React.SetStateAction<string[]>>; 
  setJsonFields: React.Dispatch<React.SetStateAction<string[]>>; 
  setXmlFields: React.Dispatch<React.SetStateAction<string[]>>; 
  setFieldsList: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileError, setFileError] = useState<string | null>(null);

  useEffect(() => {
    setFieldsList([])
    setCsvFields([])
    setJsonFields([])
    setXmlFields([])
  },[setCsvFields, setFieldsList, setJsonFields, setXmlFields])

  const handleButtonClick = (format: string) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.onchange = (e) => {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        handleFileChange(files, format); 
      }
    };
    input.click();
  };

  // Drag and Drop file handling
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };
  // Handle file drop
  const handleDrop = (e: React.DragEvent<HTMLDivElement>, format: string) => {
    e.preventDefault();
    setIsDragOver(false);
    const droppedFiles = e.dataTransfer.files;
    handleFileChange(droppedFiles, format);
  };

  const handleFileChange = (files: FileList | null, format: string) => {
    if (files && files.length > 0) {
      const validFiles = Array.from(files).filter((file) => {
        const fileType = file.type;
        if (fileType === "text/csv") {
          return true;
        }
        return false;
      });

      if (validFiles.length === 0) {
        setFileError(`Please upload a valid file CSV.`);
      } else {
        setFileError(null);
        if (format === "csv") {
          setCsvFiles((prevCsvFiles) => [...prevCsvFiles, ...validFiles]);
        }
        if (format === "json") {
          setJsonFiles((prevJsonFiles) => [...prevJsonFiles, ...validFiles]); 
        }
        if (format === "xml") {
          setXmlFiles((prevXmlFiles) => [...prevXmlFiles, ...validFiles]); 
        }
        if (format === "") {
          setFiles((prevSameFiles) => [...prevSameFiles, ...validFiles]);
        }
      }
    }
  };

  const handleCancel = (fileToRemove: File, format: string) => {
    if (format === "") {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file !== fileToRemove)
      );
    }
    if (format === "json") {
      setJsonFiles((prevFiles) =>
        prevFiles.filter((file) => file !== fileToRemove)
      );
    }
    if (format === "csv") {
      setCsvFiles((prevFiles) =>
        prevFiles.filter((file) => file !== fileToRemove)
      );
    }
    if (format === "xml") {
      setXmlFiles((prevFiles) =>
        prevFiles.filter((file) => file !== fileToRemove)
      );
    }
  };

  return (
    <div>
      {isToggledForHeader ? (
        <>
          <div className="flex flex-col items-center overflow-auto w-full">
            <h2 className="text-lg font-semibold mb-2">Upload CSV file</h2>
            <div
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={(e) => handleDrop(e, "")}
              className={`border-dashed border-2 p-4 transition-colors ${
                isDragOver ? "bg-blue-200" : "bg-gray-50"
              }`}
            >
              <input
                type="file"
                accept=".csv"
                onChange={(e) => handleFileChange(e.target.files, "")}
                className="hidden"
                multiple
              />
              <div className="flex flex-col items-center text-center">
                <p>Drag 'n' drop some files here, or click to select files</p>
                <button
                  onClick={() => handleButtonClick("")}
                  className="mt-2 w-36 bg-blue-600 text-white px-4 py-2 rounded transition-colors hover:bg-blue-700"
                >
                  Browse Files
                </button>
              </div>
            </div>
            {fileError && <p className="text-red-600 mt-2">{fileError}</p>}
            <div className="mt-4 w-96">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="relative flex items-center justify-between p-2 w-full border-2 border-gray-200 rounded-lg bg-gray-50 mb-2 shadow-sm"
                >
                  <div className="flex items-center">
                    <p className="text-gray-700">{file.name}</p>
                  </div>
                  <IconX onClick={() => handleCancel(file, "")} stroke={1} />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="flex space-x-4">
            {/* CSV Section */}
            {supportedOutput.includes("csv") && (
              <div className="flex-1">
                <p className="font-bold">Add fields for CSV</p>
                <div
                  onDragOver={(e) => handleDragOver(e)}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, "csv")}
                  // className="bg-white p-4  w-80 rounded-lg"
                  className={`border-dashed border-2 p-4 transition-colors ${
                    isDragOver ? "bg-blue-200" : "bg-gray-50"
                  }`}
                >
                  <input
                    type="file"
                    accept=".csv"
                    onChange={(e) => handleFileChange(e.target.files, "csv")}
                    className="hidden"
                    multiple
                  />
                  <div className="flex flex-col items-center text-center">
                    <p>Choose a file or Drag & drop it here </p>
                    <button
                      onClick={() => handleButtonClick("csv")}
                      className="mt-2 w-36 bg-blue-600 text-white px-4 py-2 rounded transition-colors hover:bg-blue-700"
                    >
                      Browse Files
                    </button>
                  </div>
                </div>
                {fileError && <p className="text-red-600 mt-2">{fileError}</p>}
                <div className="mt-4 w-96">
                  {csvFiles.map((file, index) => (
                    <div
                      key={index}
                      className="relative flex items-center justify-between p-2 w-80 border-2 border-gray-200 rounded-lg bg-gray-200 mb-2 shadow-sm"
                    >
                      <div className="flex items-center">
                        <p className="text-gray-700">{file.name}</p>
                      </div>
                      <IconX
                        onClick={() => handleCancel(file, "csv")}
                        stroke={1}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* JSON Section */}
            {supportedOutput.includes("json") && (
              <div className="flex-1">
                <p className="font-bold">Add fields for JSON</p>
                <div
                  onDragOver={(e) => handleDragOver(e)}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, "json")}
                  // className="bg-white p-4  w-80 rounded-lg"
                  className={`border-dashed border-2 p-4 transition-colors ${
                    isDragOver ? "bg-blue-200" : "bg-gray-50"
                  }`}
                >
                  <input
                    type="file"
                    accept=".csv, .json, .xml"
                    onChange={(e) => handleFileChange(e.target.files, "json")}
                    className="hidden"
                    multiple
                  />
                  <div className="flex flex-col items-center text-center">
                    <p>Choose a file or Drag & drop it here </p>
                    <button
                      onClick={() => handleButtonClick("json")}
                      className="mt-2 w-36 bg-blue-600 text-white px-4 py-2 rounded transition-colors hover:bg-blue-700"
                    >
                      Browse Files
                    </button>
                  </div>
                </div>
                {fileError && <p className="text-red-600 mt-2">{fileError}</p>}
                <div className="mt-4 w-96">
                  {jsonFiles.map((file, index) => (
                    <div
                      key={index}
                      className="relative flex items-center justify-between p-2 w-80 border-2 border-gray-200 rounded-lg bg-gray-200 mb-2 shadow-sm"
                    >
                      <div className="flex items-center">
                        <p className="text-gray-700">{file.name}</p>
                      </div>
                      <IconX
                        onClick={() => handleCancel(file, "json")}
                        stroke={1}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* XML Section */}
            {supportedOutput.includes("xml") && (
              <div className="flex-1">
                <p className="font-bold">Add fields for XML</p>
                <div
                  onDragOver={(e) => handleDragOver(e)}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, "xml")}
                  // className="bg-white p-4  w-80 rounded-lg"
                  className={`border-dashed border-2 p-4 transition-colors ${
                    isDragOver ? "bg-blue-200" : "bg-gray-50"
                  }`}
                >
                  <input
                    type="file"
                    accept=".csv, .json, .xml"
                    onChange={(e) => handleFileChange(e.target.files, "xml")}
                    className="hidden"
                    multiple
                  />
                  <div className="flex flex-col items-center text-center">
                    <p>Choose a file or Drag & drop it here </p>
                    <button
                      onClick={() => handleButtonClick("xml")}
                      className="mt-2 w-36 bg-blue-600 text-white px-4 py-2 rounded transition-colors hover:bg-blue-700"
                    >
                      Browse Files
                    </button>
                  </div>
                </div>
                {fileError && <p className="text-red-600 mt-2">{fileError}</p>}
                <div className="mt-4 w-96">
                  {xmlFiles.map((file, index) => (
                    <div
                      key={index}
                      className="relative flex items-center justify-between p-2 w-80 border-2 border-gray-200 rounded-lg bg-gray-200 mb-2 shadow-sm"
                    >
                      <div className="flex items-center">
                        <p className="text-gray-700">{file.name}</p>
                      </div>
                      <IconX
                        onClick={() => handleCancel(file, "xml")}
                        stroke={1}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddFieldsFromFile;
