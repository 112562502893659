import { IconX } from "@tabler/icons-react";
import { useEffect } from "react";

interface ManuallyAddFieldsProps {
  // handleAddField: (format:string) => void;
  handleAddField: (format: string) => void;
  handleRemoveField: (index: number, format: string) => void;
  isToggledForHeader: boolean;
  fieldValue: string;
  setFieldCsvValue: React.Dispatch<React.SetStateAction<string>>;
  setFieldJsonValue: React.Dispatch<React.SetStateAction<string>>;
  setFieldXmlValue: React.Dispatch<React.SetStateAction<string>>;
  setFieldValue: React.Dispatch<React.SetStateAction<string>>;
  fieldsList: string[];
  xmlFields: string[];
  jsonFields: string[];
  csvFields: string[];
  supportedOutput: string[];
  fieldCsvValue: string;
  fieldJsonValue: string;
  fieldXmlValue: string;
  // isError:boolean
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setCsvFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setJsonFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setXmlFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const ManuallyAddFields: React.FC<ManuallyAddFieldsProps> = ({
  handleAddField,
  handleRemoveField,
  isToggledForHeader,
  fieldValue,
  setFieldCsvValue,
  setFieldJsonValue,
  setFieldXmlValue,
  setFieldValue,
  fieldsList,
  xmlFields,
  jsonFields,
  csvFields,
  supportedOutput,
  fieldCsvValue,
  fieldJsonValue,
  fieldXmlValue,
  setFiles,
  setCsvFiles,
  setJsonFiles,
  setXmlFiles,
}) => {
  useEffect(() => {
    // console.log("in manually jsonFields",jsonFields)
    // console.log("in manually supportedOutput",supportedOutput)
    // console.log("in manually fieldsList",fieldsList)
    //   setFiles([])
    //   setCsvFiles([])
    // setJsonFiles([])
    // setXmlFiles([])
  }, [setCsvFiles, setFiles, setJsonFiles, setXmlFiles]);
  return (
    <div>
      {isToggledForHeader ? (
        <>
          <div className="flex items-center justify-center mb-4 mt-2">
            <input
              type="text"
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              placeholder="Enter field name"
              className="border border-gray-300 rounded-lg p-2 mr-2 w-72 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={() => handleAddField("")}
              className="bg-blue-600 text-white rounded-lg py-2 px-4 transition-colors hover:bg-blue-700"
            >
              Add Field
            </button>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-2">
            {fieldsList.map((field: string, index: number) => (
              <div
                key={index}
                className="relative flex items-center justify-between p-2 w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 border-2 border-gray-200 rounded-lg bg-gray-200 mb-2"
              >
                <div className="flex items-center">
                  <div className="ml-4 flex flex-col">
                    <p className="text-gray-700">{field}</p>
                  </div>
                </div>
                <IconX
                  onClick={() => handleRemoveField(index, "")}
                  stroke={1}
                />
              </div>
            ))}
          </div>
          {/* <div className="flex items-center justify-center  ">
            {fieldsList.map((field: string, index: number) => (
              <div
                key={index}
                className="relative flex items-center justify-between p-2 w-96 border-2 border-gray-200 rounded-lg bg-gray-200 mb-2"
              >
                <div className="flex items-center">
                  <div className="ml-4 flex flex-col">
                    <p className="text-gray-700"> {field}</p>
                  </div>
                </div>
                <IconX
                  onClick={() => handleRemoveField(index, "csv")}
                  stroke={1}
                />
              </div>
            ))}
          </div> */}
        </>
      ) : (
        <div>
          <div className="flex space-x-12 ml-1">
            {/* CSV Section */}
            {supportedOutput.includes("csv") && (
              <div className="flex-1">
                <p className="mt-2 font-bold">Add fields for CSV</p>
                <div className="flex items-center mb-4">
                  <input
                    type="text"
                    value={fieldCsvValue}
                    onChange={(e) => setFieldCsvValue(e.target.value)}
                    placeholder="Enter field name"
                    className="border border-gray-300 rounded-lg p-2 mr-2 w-64 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <button
                    onClick={() => handleAddField("csv")}
                    className="bg-blue-600 text-white rounded-lg py-2 px-4 transition-colors hover:bg-blue-700"
                  >
                    Add
                  </button>
                </div>
                {csvFields.map((field: string, index: number) => (
                  <div
                    key={index}
                    className="relative flex items-center justify-between p-2 w-80 border-2 border-gray-200 rounded-lg bg-gray-200 mb-2"
                  >
                    <div className="flex items-center">
                      <div className="ml-4 flex flex-col">
                        <p className="text-gray-700"> {field}</p>
                      </div>
                    </div>
                    <IconX
                      onClick={() => handleRemoveField(index, "csv")}
                      stroke={1}
                    />
                  </div>
                ))}
              </div>
            )}
            {/* {isError && (csvFields.length === 0) && <p className="text-red-500">Please add the fields <fieldset></fieldset>.</p>} */}

            {/* JSON Section */}
            {supportedOutput.includes("json") && (
              <div className="flex-1">
                <p className="mt-2 font-bold">Add fields for JSON</p>
                <div className="flex items-center mb-4">
                  <input
                    type="text"
                    value={fieldJsonValue}
                    onChange={(e) => setFieldJsonValue(e.target.value)}
                    placeholder="Enter field name"
                    className="border border-gray-300 rounded-lg p-2 mr-2 w-64 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <button
                    onClick={() => handleAddField("json")}
                    className="bg-blue-600 text-white rounded-lg py-2 px-4 transition-colors hover:bg-blue-700"
                  >
                    Add
                  </button>
                </div>
                {jsonFields.map((field: string, index: number) => (
                  <div
                    key={index}
                    className="relative flex items-center justify-between p-2 w-80 border-2 border-gray-200 rounded-lg bg-gray-200 mb-2"
                  >
                    <div className="flex items-center">
                      <div className="ml-4 flex flex-col">
                        <p className="text-gray-700"> {field}</p>
                      </div>
                    </div>
                    <IconX
                      onClick={() => handleRemoveField(index, "json")}
                      stroke={1}
                    />
                  </div>
                ))}
              </div>
            )}
            {/* {isError && (jsonFields.length === 0) && <p className="text-red-500">Please add the fields <fieldset></fieldset>.</p>} */}
            {/* XML Section */}
            {supportedOutput.includes("xml") && (
              <div className="flex-1">
                <p className="mt-2 font-bold">Add fields for XML</p>
                <div className="flex items-center mb-4">
                  <input
                    type="text"
                    value={fieldXmlValue}
                    onChange={(e) => setFieldXmlValue(e.target.value)}
                    placeholder="Enter field name"
                    className="border border-gray-300 rounded-lg p-2 mr-2 w-64 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <button
                    onClick={() => handleAddField("xml")}
                    className="bg-blue-600 text-white rounded-lg py-2 px-4 transition-colors hover:bg-blue-700"
                  >
                    Add
                  </button>
                </div>
                {/* Render added XML fields */}
                {xmlFields.map((field: string, index: number) => (
                  <div
                    key={index}
                    className="relative flex items-center justify-between p-2 w-80 border-2 border-gray-200 rounded-lg bg-gray-200 mb-2"
                  >
                    <div className="flex items-center">
                      <div className="ml-4 flex flex-col">
                        <p className="text-gray-700"> {field}</p>
                      </div>
                    </div>
                    <IconX
                      onClick={() => handleRemoveField(index, "xml")}
                      stroke={1}
                    />
                  </div>
                ))}
              </div>
            )}
            {/* {isError && (xmlFields.length === 0) && <p className="text-red-500">Please add the fields <fieldset></fieldset>.</p>} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManuallyAddFields;
