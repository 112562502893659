import { SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import Modal from "../../../components/Modal";
import { EDIT_JSON, VIEW_JSON } from "../../../utils/api-details/ApiList";
import { useToast } from "../../../components/Toast";
interface DataType {
  [key: string]: any;
}

export const EditJson = ({
  isEditModalOpen,
  onClose,
  onCancel,
  handleSave,
  fileName,
  selectedOption,
  format_name,
  orgID,
  userID,
  selectedVersion
}: {
  isEditModalOpen?: boolean;
  onClose: () => void;
  onCancel: () => void;
  handleSave: (parsedData: DataType) => void;
  fileName?: string;
  selectedOption?: string;
  format_name?: string;
  orgID?: string;
  userID?: string;
  selectedVersion?: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jsonData, setJsonData] = useState("");
  const [editableData, setEditableData] = useState("");
  const [type, setType] = useState("");
  const view_json = VIEW_JSON;
  const edit_json = EDIT_JSON;
  const { showToast } = useToast();

  const updatedFile = () => {
    const name = fileName + ".json";
    axios.get(`${apiUrl + view_json}/${name}`, {
        params: {
          format_name: format_name,
          version:selectedVersion === ""? 0 :selectedVersion,
          type: type,
          orgid: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        setJsonData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        showToast("Something went wrong", "error");
      });
  };

  useEffect(() => {
    if (selectedOption === "original_json") {
      setType("original");
    } else {
      setType("appended");
    }
    if (isEditModalOpen) {
      setIsModalOpen(true);
      updatedFile();
    } else {
      setIsModalOpen(false);
    }
  }, [isEditModalOpen, selectedOption]);

  useEffect(() => {
    setEditableData(JSON.stringify(jsonData, null, 2));
  }, [jsonData]);

  // Function to handle changes in the text area
  const handleTextChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setEditableData(event.target.value);
  };


  const handleSaveClick = () => {
    axios.patch(`${apiUrl + edit_json}/${fileName + ".json"}`, JSON.parse(editableData),
        {
          params: {
            format_name: format_name,
            selected_version: selectedVersion === ""? 0 :selectedVersion,
            type: type,
            orgid: orgID,
            user_id: userID,
          },
        }
      )
      .then((response) => {
        updatedFile();
      })
      .catch(function (error) {
        console.log(error);
        showToast("Something went wrong", "error");
      });

    const parsedData = JSON.parse(editableData);
    handleSave(parsedData);
    setIsModalOpen(false);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
    onCancel();
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (onClose) onClose();
        }}
        title={fileName + " - JSON"}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col">
          <div className="mt-auto flex justify-end space-x-4">
            <button
              type="button"
              className="px-4 py-2 w-32  bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              type="button"
              className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="h-96 items-center justify-between mt-12 ">
          <textarea
            className="p-4 rounded-lg h-full overflow-x-auto  w-full max-w-full border border-gray-300"
            value={editableData}
            onChange={handleTextChange}
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              whiteSpace: "nowrap",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EditJson;
