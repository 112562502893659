import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/Modal";

const FormatConfirmation = ({
  open,
  onClose,
  handleRename
}: {
  open: boolean;
  onClose: () => void;
  handleRename: () => void;
}) => {
  const navigate = useNavigate();

  const handleUpdateFormat = () => {
    navigate("/update-format");
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title="Validate Format Name"
      showCancelButton={false}
      size="w-5/12"
      isCrossIcon={false}
    >
      <div
        className="max-h-96">
        The format Name already exists.
        <br />
        Do you want to change the format name or do you need to update the format?
        <div className="flex space-x-4 mt-6">
          <button
            onClick={handleRename}
            className={`px-4 py-2 rounded bg-blue-600 text-white`}
          >
            Rename
          </button>
          <button
            onClick={handleUpdateFormat}
            className={`px-4 py-2 rounded bg-blue-600 text-white`}
          >
            Update format
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FormatConfirmation;
