import pdf from "./../../assets/pdf-icon.png";
import word from "./../../assets/word-icon.png";
import excel from "./../../assets/excel-icon.png";
import csv from "./../../assets/csv.png";
import { useState } from "react";
import OtherProduct from "./OtherProduct";
import NeedNewFormat from "./NeedNewFormat";
import FileUpload from "../dashboard/FileUpload";
import './supported.css'

const SupportedInvoice = ({isIconClick}:{isIconClick :boolean}) => {
  // const navigate = useNavigate();
  const [isOtherProduct,setIsOtherProduct] = useState(false)
  const [isNeedNewFormat,setIsNeedNewFormat] = useState(false)
  const [isUploadFile,setIsUploadFile] = useState(false)
  


  const handlePdf = () => {
    setIsUploadFile(true)
    // navigate("/uploadFile");
  };

  const handleOtherProduct = () =>{
    setIsOtherProduct(true)
    // console.log("isOtherProduct",isOtherProduct)
  }

  const handleClose = () => {
    setIsOtherProduct(false)
  }

  const handleNewFormat = () =>{
    setIsNeedNewFormat(true)
  }

  const handleCloseNewFormat  = () => {
    setIsNeedNewFormat(false)
  }

  const HandleCloseUploadFile = () => {
    setIsUploadFile(false)
  }
  return (
    <div className="mt-6">
      <h1 className=" text-3xl font-bold font-Inter text-center">
        Supported Invoice Formats
      </h1>
      <div className="flex flex-col items-center  my-2 ">
      {!isIconClick && (
      <div className="flex space-x-36">
          <img src={pdf} alt="pdf-icon" className="w-32 h-32 p-6 " />
          <img src={excel} alt="excel-icon" className="w-32 h-32 p-6 " />
          <img src={csv} alt="csv-icon" className="w-32 h-32 p-6 " />
          <img src={word} alt="word-icon" className="w-32 h-32 p-6 " />
        </div>
      )}
       {isIconClick && (
        <div className="flex space-x-10">
          <div className="relative group inline-block" onClick={handlePdf}>
            <img src={pdf} alt="pdf-icon" className="w-24 h-24 p-2 " />
            <div className="absolute left-0 w-32 bg-blue-50 text-center text-sm font-normal rounded-lg p-1 opacity-0 group-hover:opacity-100 transition duration-200">
              Upload Pdf
            </div>
          </div>
          <div className="relative group inline-block blur-0">
          <img src={excel} alt="word-icon" className="w-24 h-24 p-2 hover:filter hover:grayscale hover:pointer-events-none" />
          </div>
          <div className="relative group inline-block" >
          <img src={csv} alt="word-icon" className="w-24 h-24 p-2 hover:filter hover:grayscale hover:pointer-events-none" />
          </div>
          <div className="relative group inline-block">
          <img src={word} alt="word-icon" className="w-24 h-24 p-2 hover:filter hover:grayscale hover:pointer-events-none" />
          </div>
        </div>
 

        )} 
      </div>

      <div className = {`flex flex-col items-center ${isIconClick ? "mt-10" : "mt-0"} `}>
        <div className="flex space-x-4 ">
          <button
            type="button"
            className="px-4 py-2 w-44 bg-blue-700 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
            onClick={handleNewFormat}
          >
            Need New Format?
          </button>
          <button
            type="button"
            className="px-4 py-2 w-44 bg-blue-700 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
          onClick={handleOtherProduct}
          >
            Our Other Product
          </button>
        </div>
      </div>

      {isOtherProduct && <OtherProduct isOpen={true} onClose={handleClose}/>}
      {isNeedNewFormat && <NeedNewFormat isOpen={true} onClose={handleCloseNewFormat}/>}
      {isUploadFile && <FileUpload open={true} closeUploadFileModal={HandleCloseUploadFile} showModal={true}/>}
    </div>
  );
};

export default SupportedInvoice;
