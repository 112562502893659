import React from 'react';

interface ConfirmConfigureProps {
  formatName?: string;
  supportedOutput: string[];
  isToggledForHeader?: boolean;
  isConfigureLookup?: boolean;
}

const ConfirmConfigure: React.FC<ConfirmConfigureProps> = ({
  formatName,
  supportedOutput,
  isToggledForHeader,
  isConfigureLookup
}) => {
  return (
    <div className="p-10 h-80  bg-white rounded-lg shadow-sm ">
      <h2 className="text-2xl font-bold mb-4">Confirm Configuration</h2>
      <h3 className="text-lg mb-3">Below are the final configurations for <strong >{formatName}</strong>:</h3>
      <ul className="list-disc pl-5 space-y-2">
        <li className="text-gray-700">Name: <strong>{formatName}</strong></li>
        <li className="text-gray-700">Supported Outputs: <strong>{supportedOutput.join(', ')}</strong></li>
        <li className="text-gray-700">All output formats have <strong>{isToggledForHeader ? 'the same' : 'different'}</strong> header fields.</li>
        <li className="text-gray-700">Field mapping for all formats has been completed.</li>
        {isConfigureLookup ? 
        <li className="text-gray-700">Added the Buyer/Supplier lookup.</li>
        :
        <li className="text-gray-700">Not added the Buyer/Supplier lookup.</li>}
      </ul>
      
    </div>
  );
};

export default ConfirmConfigure;
