import React, { useState } from "react";
import ViewCsv from "../supportedFormats/csv/ViewCsv";
import ViewXml from "../supportedFormats/xml/ViewXml";
import { useToast } from "../../components/Toast";
import ViewJson from "../supportedFormats/json/ViewJson";

interface InvoiceData {
  invoiceName: string;
  CSV_Count: number;
  XML_Count: number;
  JSON_Count: number;
  PDF_Count: number;
  FINVOICE_NUMBER: string;
  FINVOICE_TYPE: string;
  FINVOICE_NAME: string;
  FORMAT_NAME: string;
  FINVOICE_ID: number;
}

interface InvoiceTableProps {
  invoiceData: InvoiceData[];
}

const HistoryTable: React.FC<InvoiceTableProps> = ({ invoiceData }) => {
  const [fileName, setFileName] = useState("");
  const [isView, setIsView] = useState(false);
  const [formatName, setFormatName] = useState("");
  const [latestVersion, setLatestVersion] = useState<number>();
  const [type, setType] = useState("");
  const { showToast } = useToast();
  const [invoiceType, setInvoiceType] = useState("");

  const handleInvoiceClick = (
    invoiceName: string,
    formatName: string,
    count: number,
    invoiceType: string,
    type: string
  ) => {
    setFormatName(formatName);
    const name = invoiceName.split("_mapped")[0];
    setFileName(name);
    setType(type);
    setInvoiceType(invoiceType)
    setLatestVersion(count - 1);
    if (count > 0) {
      setIsView(true);
    } else {
      showToast("No version found", "error");
    }
  };

  const handleClose = () => {
    setIsView(false);
  };

    // Sorting invoiceData 
    const sortedInvoiceData = [...invoiceData].sort((a, b) => b.FINVOICE_ID - a.FINVOICE_ID);

  return (
    <div>
      <div className="overflow-x-auto">
        {sortedInvoiceData.length !== 0 ? (
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-200  uppercase text-xs leading-normal">
                <th className="py-3 px-6 text-left">Invoice Number</th>
                <th className="py-3 px-6 text-left">Invoice Type</th>
                <th className="py-3 px-6 text-left">CSV</th>
                <th className="py-3 px-6 text-left">XML</th>
                <th className="py-3 px-6 text-left">JSON</th>
              </tr>
            </thead>
            <tbody className=" text-sm ">
              {sortedInvoiceData.map((invoice, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td className="py-3 px-6">{invoice.FINVOICE_NUMBER}</td>
                  <td className="py-3 px-6">{invoice.FINVOICE_TYPE}</td>
                  <td
                    className="py-3 px-6 cursor-pointer text-blue-600 hover:underline"
                    onClick={() =>
                      handleInvoiceClick(
                        invoice.FINVOICE_NAME,
                        invoice.FORMAT_NAME,
                        invoice.CSV_Count,
                        invoice.FINVOICE_TYPE,
                        "csv"
                      )
                    }
                  >
                    {invoice.CSV_Count}
                  </td>
                  <td
                    className="py-3 px-6 cursor-pointer text-blue-600 hover:underline"
                    onClick={() =>
                      handleInvoiceClick(
                        invoice.FINVOICE_NAME,
                        invoice.FORMAT_NAME,
                        invoice.XML_Count,
                        invoice.FINVOICE_TYPE,
                        "xml"
                      )
                    }
                  >
                    {invoice.XML_Count}
                  </td>
                  <td
                    className="py-3 px-6 cursor-pointer text-blue-600 hover:underline"
                    onClick={() =>
                      handleInvoiceClick(
                        invoice.FINVOICE_NAME,
                        invoice.FORMAT_NAME,
                        invoice.JSON_Count,
                        invoice.FINVOICE_TYPE,
                        "json"
                      )
                    }
                  >
                    {invoice.JSON_Count}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center text-gray-600">No Data</p>
        )}
      </div>
      {type === "json" && (
        <ViewJson
          isViewModalOpen={isView}
          onClose={handleClose}
          uploadedFileName={fileName}
          format_name={formatName}
          latestVersion={latestVersion}
          invoiceType = {invoiceType}
        />
      )}
      {type === "csv" && (
        <ViewCsv
          isCsvViewModalOpen={isView}
          onClose={handleClose}
          uploadedFileName={fileName}
          format_name={formatName}
          latestVersion={latestVersion}
          invoiceType = {invoiceType}
        />
      )}
      {type === "xml" && (
        <ViewXml
          isXmlView={isView}
          onClose={handleClose}
          uploadedFileName={fileName}
          format_name={formatName}
          latestVersion={latestVersion}
          invoiceType = {invoiceType}
        />
      )}
    </div>
  );
};

export default HistoryTable;
