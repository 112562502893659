import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import axios from "axios";
import EditXml from "./EditXml";
import { IconTriangleInvertedFilled } from "@tabler/icons-react";
import CalculatorPage from "../../../components/Calculator";
import { downloadXmlFile } from "../../../components/Download";
import Dropdown from "react-dropdown";
import { useToast } from "../../../components/Toast";
import {
  INVOICE_VERSION_BY_INVOICE_NAME,
  VIEW_XML,
} from "../../../utils/api-details/ApiList";

export const ViewXml = ({
  isXmlView,
  onClose,
  uploadedFileName,
  format_name,
  latestVersion,
  invoiceType,
}: {
  isXmlView?: boolean;
  onClose: () => void;
  uploadedFileName?: string;
  format_name?: string;
  latestVersion?: number;
  invoiceType?: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [xmlData, setXmlData] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [selectedVersion, setSelectedVersion] = useState<string>("");
  const [versionList, setVersionList] = useState([]);
  const [error, setError] = useState("");
  const { showToast } = useToast();
  const view_xml = VIEW_XML;
  const invoice_version_by_invoice_name = INVOICE_VERSION_BY_INVOICE_NAME;

  useEffect(() => {
    // setSelectedOption("");
    if (isXmlView) {
      setIsModalOpen(true);
      previewFile(invoiceType ? invoiceType : "appended");
      getVersionList("");
    } else {
      setIsModalOpen(false);
      setSelectedOption("");
    }
    if (uploadedFileName) {
      const fileName = uploadedFileName?.split(".")[0];
      setFileName(fileName);
    }
    if (selectedVersion) {
      previewFile(
        selectedOption === "original_xml" || invoiceType === "original"
          ? "original"
          : "appended"
      );
      getVersionList(
        selectedOption === "original_xml" ? "original_xml" : "appended_xml"
      );
    }
  }, [apiUrl, fileName, isXmlView, uploadedFileName, selectedVersion]);

  const previewFile = (type: string) => {
    if (fileName !== "") {
      axios
        .get(`${apiUrl + view_xml}/${fileName + ".xml"}`, {
          params: {
            format_name: format_name,
            version:
              selectedVersion || latestVersion
                ? selectedVersion || latestVersion
                : 0,
            type: type,
            orgid: orgID,
            user_id: userID,
          },
        })
        .then((response) => {
          setXmlData(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setError(error?.response?.data?.detail);
        });
    }
  };

  const getVersionList = async (optionSelected: string) => {
    const payload = {
      invoice_name: fileName + ".xml",
      format_name: format_name,
      org_id: orgID,
      user_id: userID,
    };
    if (fileName !== "") {
      const response = await axios
        .post(`${apiUrl + invoice_version_by_invoice_name}`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log("response.data", response?.data?.data?.results);
          if (response?.data?.data?.results) {
            let allVersions = [];
            if (
              optionSelected === "original_xml" ||
              invoiceType === "original"
            ) {
              const originalData = response.data.data.results.filter(
                (item: { FINVOICE_TYPE: string }) =>
                  item.FINVOICE_TYPE === "ORIGINAL" ||
                  item.FINVOICE_TYPE === "original"
              );
              allVersions =
                originalData.length > 0
                  ? originalData[0].all_version?.split(",")
                  : [];
            } else if (
              optionSelected === "appended_xml" ||
              optionSelected === "" ||
              invoiceType === "appended"
            ) {
              const appendedData = response.data.data.results.filter(
                (item: { FINVOICE_TYPE: string }) =>
                  item.FINVOICE_TYPE === "APPENDED" ||
                  item.FINVOICE_TYPE === "appended"
              );
              allVersions =
                appendedData.length > 0
                  ? appendedData[0].all_version?.split(",")
                  : [];
            }
            setVersionList(allVersions);
          }
        })
        .catch(function (error) {
          console.log(error);
          showToast(
            "Something went wrong while getting invoice version!",
            "error"
          );
        });
      console.log("response", response);
    }
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleClosePopup = () => {
    setIsEditModalOpen(false);
    setIsModalOpen(false);
    if (onClose) onClose();
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleSave = (updatedData: any) => {
    setXmlData(updatedData);
    setIsEditModalOpen(false);
    getVersionList(selectedOption ?? "");
  };

  const handleDownload = async () => {
    const fileName = uploadedFileName?.split(".")[0] + ".xml";
    const type = selectedOption === "original_xml" || invoiceType === "original" ? "original" : "appended";
    await downloadXmlFile(apiUrl, fileName, type, format_name, selectedVersion,latestVersion)
      .then(() => {
        console.log("Xml downloaded successfully");
      })
      .catch((error) => {
        console.error("Error downloading xml:", error?.response?.statusText);
        const errorMsg = error?.response?.statusText
          ? "File " + error?.response?.statusText
          : "Something went wrong!";
        showToast(errorMsg, "error");
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    getVersionList(value);
    switch (value) {
      case "original_xml":
        previewFile("original");
        break;
      case "appended_xml":
        previewFile("appended");
        break;
      default:
        setSelectedOption("");
        break;
    }
  };

  const formattedXmlData = (xmlData: any) => {
    return xmlData.replace(/</g, "\n<").replace(/\n\n/g, "\n");
  };

  const handleSelectedVersion = (selected: { value: string }) => {
    // const value = event.target.value;
    const value = selected.value;
    setSelectedVersion(value);
    setError("");
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (onClose) onClose();
        }}
        title={fileName + " - XML"}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col ">
          <div className="mt-auto flex justify-end space-x-4">
            <div className="border border-gray-300 rounded-lg relative">
              <select
                className="appearance-none px-6 py-2 border border-blue-700 text-blue-600 rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
                id="dropdown"
                value={selectedOption}
                onChange={handleChange}
              >
                <option value="">View Option</option>
                {selectedOption === "original_xml"  || invoiceType === "original" ? (
                  <option value="appended_xml">Appended XML</option>
                ) : (
                  <option value="original_xml">Original XML</option>
                )}
              </select>
              <div className="pointer-events-none absolute inset-y-0 -right-1 flex items-center px-2 text-gray-700">
                <IconTriangleInvertedFilled className="w-4 h-2 text-gray-400 " />
              </div>
            </div>
            <Dropdown
              options={versionList}
              onChange={handleSelectedVersion}
              value={selectedVersion}
              placeholder="Select Version"
              controlClassName="w-full px-4 py-2 !border-1 !border-blue-700 !rounded-lg !text-blue-600 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              menuClassName="border-2 border-blue-500 rounded-lg bg-white shadow-lg max-h-60 overflow-y-auto"
              arrowClassName="mt-1"
            />
            <button
              type="button"
              className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              onClick={handleEdit}
            >
              Edit
            </button>
            <button
              type="button"
              className="px-4 py-2 w-32  bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleDownload}
            >
              Download
            </button>
            <CalculatorPage />
          </div>
        </div>
        <h1 className="font-bold text-sm mb-4">
          {selectedOption === "original_xml" || invoiceType === "original"
            ? "Original XML"
            : "Appended XML"}
        </h1>
        <div
          className="h-96  items-center justify-between  overflow-y-scroll "
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
          }}
        >
          {error && error}
          {!error && (
            <div>
              <pre className="p-4 rounded-lg overflow-x-auto whitespace-pre-wrap w-max">
                {xmlData ? formattedXmlData(xmlData) : "Loading..."}
              </pre>
            </div>
          )}
        </div>
      </Modal>

      <EditXml
        isEditModalOpen={isEditModalOpen}
        onClose={handleClosePopup}
        onCancel={handleCancel}
        handleSave={handleSave}
        fileName={fileName}
        selectedOption={selectedOption}
        format_name={format_name}
        orgID={orgID ?? ""}
        userID={userID ?? ""}
        selectedVersion={selectedVersion}
      />
    </div>
  );
};

export default ViewXml;
