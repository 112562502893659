import React, { useState, useEffect } from "react";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import loginPagePic from "./../../assets/LoginScreenGraphic.svg";
import productIcon from "./../../assets/productIcon.svg";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { RESET_PASSWORD } from "../../utils/api-details/ApiList";

const ResetPasswordSchema = z
  .object({
    newPassword: z
      .string()
      .min(8, "Password must be at least 8 characters long.")
      .regex(/[A-Za-z]/, "Password must contain at least one letter.")
      .regex(/[0-9]/, "Password must contain at least one number.")
      .regex(
        /[@$!%*?&]/,
        "Password must contain at least one special character."
      ),
    confirmPassword: z.string().min(1, "Confirm password is required."),
  })
  .superRefine((data, ctx) => {
    if (data.newPassword !== data.confirmPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Passwords must match.",
        path: ["confirmPassword"],
      });
    }
  });
type ResetPasswordData = z.infer<typeof ResetPasswordSchema>;

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [orgIdByUrl, setOrgIdByUrl] = useState("");
  const [usernameByUrl, setUsernameByUrl] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const orgID = sessionStorage.getItem("orgId");
  const username = sessionStorage.getItem("username");
  const resetComplete = sessionStorage.getItem("reset_complete");
  const reset_password = RESET_PASSWORD;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const usernameFromUrl = urlParams.get("username");
    const orgidFromUrl = urlParams.get("orgid");
    setOrgIdByUrl(orgidFromUrl || "");
    setUsernameByUrl(usernameFromUrl || "");
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ResetPasswordData>({
    resolver: zodResolver(ResetPasswordSchema),
  });

  const onSubmit = (data: ResetPasswordData) => {
    const payload = {
      username: resetComplete === "N" ? username : usernameByUrl,
      orgid: resetComplete === "N" ? parseInt(orgID ?? "") : orgIdByUrl,
      password: data.newPassword,
      request_type: resetComplete === "N" ? "FIRSTRESET" : "PWDRESET",
    };
    axios
      .post(`${apiUrl + reset_password}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
    reset();
    // navigate("/dashboard");
    // const dataString = JSON.stringify(loginData);
    // sessionStorage.setItem('userData', dataString);
    // console.log('Data stored in session storage:', sessionStorage.getItem('userData'));
  };

  return (
    <div className="grid grid-cols-12 h-screen">
      <div className="col-span-7 relative">
        <img
          src={loginPagePic}
          alt="LoginPic"
          className="p-4 w-screen h-screen"
        />
      </div>
      <div className="flex col-span-5 p-2 items-center ml-16 min-h-screen">
        <div className="w-full max-w-md p-8">
          <div className="flex justify-center">
            <img
              src={productIcon}
              alt="Invoicify"
              className="w-64 max-w-full"
            />
          </div>

          <h2 className="mt-14 text-2xl font-bold font-Inter text-center text-gray-900">
            Welcome to Invoicify
          </h2>
          <h6 className="mb-12 text-base font-Inter text-center text-gray-500">
            Please create a new password to continue.
          </h6>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8"
            noValidate
          >
            <div className="relative">
              <label
                htmlFor="newPassword"
                className="block text-sm text-left font-medium font-Inter text-gray-700"
              >
                New password
              </label>
              <input
                id="newPassword"
                type="password"
                placeholder="Password"
                {...register("newPassword")}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              {errors.newPassword && (
                <span className="text-red-500 text-sm">
                  {errors.newPassword.message}
                </span>
              )}
            </div>

            <div className="relative">
              <label
                htmlFor="confirmPassword"
                className="block text-sm text-left font-medium font-Inter text-gray-700"
              >
                Confirm New Password
              </label>
              <div className="flex items-center border border-gray-300 rounded-md shadow-sm">
                <input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  {...register("confirmPassword")}
                  className="mt-1 block w-full px-3 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="p-2 flex items-center"
                >
                  {showConfirmPassword ? (
                    <IconEyeOff stroke={1} />
                  ) : (
                    <IconEye stroke={1} />
                  )}
                </button>
              </div>
              {errors.confirmPassword && (
                <span className="text-red-500 text-sm">
                  {errors.confirmPassword.message}
                </span>
              )}
            </div>

            <div>
              <h2 className="text-sm font-bold mb-4 font-Inter">
                Password Requirements:
              </h2>
              <ul className="list-disc list-inside text-sm text-gray-500 font-Inter">
                <li>At least 8 characters long</li>
                <li>
                  Contains a mix of letters, numbers, and special characters
                </li>
              </ul>
            </div>
            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-700 text-white font-semibold font-Inter rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
