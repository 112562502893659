import React, { useEffect, useState } from "react";
import axios from "axios";
import { useToast } from "../../../../components/Toast";
import ManuallyAddFields from "./ManuallyAddFields";
import AddFieldsFromFile from "./AddFieldsFromFile";
import { ADD_BUYER_SUPPLIER } from "../../../../utils/api-details/ApiList";

interface BuyerSupplierData {
  supplierName?: string;
  buyerName?: string;
}
interface AddFieldsProps {
  setHasChanges: (value: boolean) => void;
  fieldsList: string[];
  setFieldsList: React.Dispatch<React.SetStateAction<string[]>>;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  isToggledForHeader: boolean;
  supportedOutput: string[];
  setCsvFiles: React.Dispatch<React.SetStateAction<File[]>>;
  csvFiles: File[];
  setJsonFiles: React.Dispatch<React.SetStateAction<File[]>>;
  jsonFiles: File[];
  setXmlFiles: React.Dispatch<React.SetStateAction<File[]>>;
  xmlFiles: File[];
  csvFields: string[];
  setCsvFields: React.Dispatch<React.SetStateAction<string[]>>; 
  jsonFields: string[];
  setJsonFields: React.Dispatch<React.SetStateAction<string[]>>; 
  xmlFields: string[];
  setXmlFields: React.Dispatch<React.SetStateAction<string[]>>; 
  // isError: boolean
  formatName:string;
  buyerSupplier?:BuyerSupplierData;
  isCreate:boolean;
}

const AddFields: React.FC<AddFieldsProps> = ({
  setHasChanges,
  fieldsList,
  setFieldsList,
  files,
  setFiles,
  isToggledForHeader,
  supportedOutput,
  setCsvFiles,
  csvFiles,
  setJsonFiles,
  jsonFiles,
  setXmlFiles,
  xmlFiles,
  csvFields,
  setCsvFields,
  jsonFields,
  setJsonFields,
  xmlFields,
  setXmlFields,
  formatName,
  buyerSupplier,
  isCreate
  // isError
}) => {
  const [activeTab, setActiveTab] = useState("manual");
  const [fieldValue, setFieldValue] = useState("");
  const [fieldCsvValue, setFieldCsvValue] = useState("");
  const [fieldJsonValue, setFieldJsonValue] = useState("");
  const [fieldXmlValue, setFieldXmlValue] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const orgID = sessionStorage.getItem("orgId");
  const { showToast } = useToast();
  const add_buyer_supplier = ADD_BUYER_SUPPLIER;

  useEffect(() => {
    setHasChanges(fieldsList.length > 0 || files.length > 0);
    if(isCreate){
      addBuyerSupplier()
    }
  }, [fieldsList, files, setHasChanges]);

  const handleAddField = (format: string) => {
    if (format === "csv" && fieldCsvValue.trim()) {
      // setCsvFields([])
      setCsvFields([...csvFields, fieldCsvValue]);
      setFieldCsvValue(""); 
    }
    if (format === "json" && fieldJsonValue.trim()) {
      // setJsonFields([])
      setJsonFields([...jsonFields, fieldJsonValue]);
      setFieldJsonValue("");
    }
    if (format === "xml" && fieldXmlValue.trim()) {
      // setXmlFields([])
      setXmlFields([...xmlFields, fieldXmlValue]);
      setFieldXmlValue(""); 
    }
    if (format === "" && fieldValue.trim()) {
      // setFieldsList([])
      setFieldsList((prev) => [...prev, fieldValue.trim()]);
      setFieldValue("");
      setHasChanges(true);
    }
  };

  const handleRemoveField = (index: number, format: string) => {
    if (format === "") {
      const updatedFields = fieldsList.filter((_, i) => i !== index);
      setFieldsList(updatedFields);
    }
    if (format === "json") {
      console.log("handleRemoveField Json");
      const updatedFields = jsonFields.filter((_, i) => i !== index);
      setJsonFields(updatedFields);
    }
    if (format === "csv") {
      console.log("handleRemoveField csv");
      const updatedFields = csvFields.filter((_, i) => i !== index);
      setCsvFields(updatedFields);
    }
    if (format === "xml") {
      console.log("handleRemoveField xml");
      const updatedFields = xmlFields.filter((_, i) => i !== index);
      setXmlFields(updatedFields);
    }
  };

  const addBuyerSupplier = () =>{
    const data = {
      "CONFIGURED_SUPPLIER_BUYER": {
        [formatName]: {
              "supplier": buyerSupplier?.supplierName,
              "buyer": buyerSupplier?.buyerName
          }
      }
  }
    axios.post(`${apiUrl + add_buyer_supplier}`, data,{
      params:{
        org_id:orgID,
        format_name:formatName
      }})
      .then((response) => {
      console.log(response)
      })
      .catch((error) => {
        console.error("Error fetching visit count:", error);
        showToast("Something went wrong!","error")
      });
  }

  return (
    <div>
      <div className="flex  mb-4">
        <button
          className={`flex-1 py-2 rounded-lg transition-colors ${
            activeTab === "manual"
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
          onClick={() => setActiveTab("manual")}
        >
          Add Fields Manually
        </button>
        <button
          className={`flex-1 py-2 rounded-lg transition-colors ${
            activeTab === "upload"
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
          onClick={() => setActiveTab("upload")}
        >
          Upload CSV Header
        </button>
      </div>

      <div className="h-56 flex flex-col overflow-auto">
        {activeTab === "manual" && (
          <>
            <ManuallyAddFields
              handleAddField={handleAddField}
              handleRemoveField={handleRemoveField}
              isToggledForHeader={isToggledForHeader}
              fieldValue={fieldValue}
              setFieldCsvValue={setFieldCsvValue}
              setFieldJsonValue={setFieldJsonValue}
              setFieldXmlValue={setFieldXmlValue}
              setFieldValue={setFieldValue}
              fieldsList={fieldsList}
              xmlFields={xmlFields}
              jsonFields={jsonFields}
              csvFields={csvFields}
              supportedOutput={supportedOutput}
              fieldCsvValue={fieldCsvValue}
              fieldJsonValue={fieldJsonValue}
              fieldXmlValue={fieldXmlValue}
              // isError={isError}
              setFiles={setFiles}
              setCsvFiles={setCsvFiles}
              setJsonFiles={setJsonFiles}
              setXmlFiles={setXmlFiles}
            />
          </>
        )}

        {activeTab === "upload" && (
          <div>
            <AddFieldsFromFile
              supportedOutput={supportedOutput}
              files={files}
              setFiles={setFiles}
              isToggledForHeader={isToggledForHeader}
              setCsvFiles={setCsvFiles}
              csvFiles={csvFiles}
              setJsonFiles={setJsonFiles}
              jsonFiles={jsonFiles}
              setXmlFiles={setXmlFiles}
              xmlFiles={xmlFiles}
              setCsvFields={setCsvFields}
              setJsonFields={setJsonFields}
              setXmlFields={setXmlFields}
              setFieldsList={setFieldsList}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddFields;
