import Header from "../../dashboard/Header";
import EditStepper from "./EditStepper";

const UpdateFormat = () => {
  return (
   <div>
    <Header />
      <EditStepper/>
      </div>
  );
};

export default UpdateFormat;
