// import React, { useState, useEffect, useRef } from "react";
// import profilePic from "./../../assets/profilePic.jpg";
// import productIcon from "./../../assets/productIcon.svg";

// import { useNavigate } from "react-router-dom";

// const Header = () => {
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const dropdownRef = useRef<HTMLDivElement | null>(null);
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     navigate("/");
//     setIsDropdownOpen(false);
//   };

//   const handleBack = () => {
//     navigate("/dashboard");
//   };

//   const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

//   // Close dropdown when clicking outside
//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (
//         dropdownRef.current &&
//         !dropdownRef.current.contains(event.target as Node)
//       ) {
//         setIsDropdownOpen(false);
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, []);

//   return (
// <div className="px-4 pt-4">
//   <div className="flex flex-wrap">
//     <div className="flex-shrink-0 mb-2 w-full pr-0 h-full">
//       <div className="bg-white shadow-lg rounded-lg p-2 mb-4 border-2 card">
//         <div className="flex items-center justify-between">
//           <div className="flex items-start space-x-2">
//             <img
//               src={productIcon}
//               alt="Invoicify"
//               className="w-36 max-w-full"
//               onClick={handleBack}
//             />
//           </div>
//           <div className="relative">
//             <img
//               src={profilePic}
//               alt="Profile"
//               className="w-14 h-14 rounded-full cursor-pointer"
//               onClick={toggleDropdown}
//             />
//             {isDropdownOpen && (
//               <div
//                 ref={dropdownRef}
//                 className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg z-10"
//               >
//                 <button
//                   className="w-full text-left px-4 py-2 hover:bg-gray-200"
//                   onClick={handleLogout}
//                 >
//                   Logout
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//   );
// };

// export default Header;

import React, { useState, useEffect, useRef } from "react";
import profilePic from "./../../assets/profilePic.jpg";
import productIcon from "./../../assets/productIcon.svg";
import { useNavigate } from "react-router-dom";
import { IconHome, IconMenu2 } from "@tabler/icons-react";
import History from "../history/History";
import FileUpload from "./FileUpload";
// import ConfigureNewFormat from "../adminAction/createNewFormat/ConfigureNewFormat";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [isActionVisible, setIsActionVisible] = useState(false);
  const [isHistory, setIsHistory] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isConfigure, setIsConfigure] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
        setIsActionVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    const retrievedDataString = sessionStorage.getItem("user_type");
    if (retrievedDataString === "A") {
      setIsAdmin(true);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    navigate("/");
    setIsDropdownOpen(false);
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleOptionClick = (action: string) => {
    switch (action) {
      case "History":
        setIsHistory(true);
        break;
      case "Upload File":
        setIsUploadFile(true);
        setIsUpload(true);
        break;
      case "Configure New Format":
        // setIsConfigure(true);
        navigate("/create-format");
        break;
      case "Update Configure":
        navigate("/update-format");
        break;
      case "Monitoring":
        navigate("/monitoring");
        break;
      case "Stats":
        navigate("/stats");
        break;
      case "Activate/Deactivate":
        navigate("/FormatDetails");
        break;
      default:
        break;
    }
    setIsActionVisible(false);
  };
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleAction = () => {
    setIsActionVisible(!isActionVisible);
  };

  const closeHistoryModal = () => {
    setIsHistory(false);
  };

  const HandleCloseUploadFile = () => {
    setIsUpload(false);
  };

  const closeConfigureModal = () => {
    setIsConfigure(false);
  };

  return (
    <div className="px-4 pt-4">
      <div className="flex flex-wrap">
        <div className="flex-shrink-0 mb-2 w-full pr-0 h-full">
          <div className="bg-white shadow-lg rounded-lg p-2 mb-4 border-2 card">
            <div className="flex items-center justify-between">
              <div className="flex items-start space-x-2">
                <img
                  src={productIcon}
                  alt="Invoicify"
                  className="w-36 max-w-full cursor-pointer"
                  onClick={handleBack}
                />
                <IconHome stroke={2} color="#1D4ED8" onClick={handleBack} />
              </div>
              <div className="relative flex  space-x-2">
                {/* Action Menu */}
                <IconMenu2
                  stroke={2}
                  onClick={toggleAction}
                  className="mt-3 mr-1 cursor-pointer"
                />
                {isActionVisible && (
                  <div className="absolute right-0 mt-14 w-48 max-h-96 overflow-y-auto bg-white border rounded-md shadow-lg z-30">
                    <div className="py-1" ref={dropdownRef}>
                      {!isAdmin ? (
                        <>
                          <button
                            className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                            onClick={() => handleOptionClick("History")}
                          >
                            History
                          </button>
                          <button
                            className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                            onClick={() => handleOptionClick("Upload File")}
                          >
                            Upload File
                          </button>
                        </>
                      ) : (
                        <div>
                          <button
                            className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                            onClick={() =>
                              handleOptionClick("Configure New Format")
                            }
                          >
                            Configure New Format
                          </button>
                          <button
                            className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                            onClick={() =>
                              handleOptionClick("Update Configure")
                            }
                          >
                            Update Existing Format
                          </button>
                          <button className="block w-full text-left px-4 py-2 text-gray-400 cursor-not-allowed hover:bg-gray-100">
                            Request New User
                          </button>
                          <button className="block w-full text-left px-4 py-2 text-gray-400 cursor-not-allowed hover:bg-gray-100">
                            Block User
                          </button>
                          <button
                            className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                            onClick={() => handleOptionClick("Monitoring")}
                          >
                            Monitoring
                          </button>
                          <button
                            className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                            onClick={() => handleOptionClick("Stats")}
                          >
                            Stats
                          </button>
                          <button
                            className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                            onClick={() =>
                              handleOptionClick("Activate/Deactivate")
                            }
                          >
                            Activate/Deactivate Format
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <img
                  src={profilePic}
                  alt="Profile"
                  className="w-12 h-12 rounded-full cursor-pointer"
                  onClick={toggleDropdown}
                />
                {isDropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="absolute right-0 mt-14 w-48 bg-white text-black rounded shadow-lg z-30"
                  >
                    <button
                      className="w-full text-left px-6 py-2 border-2 hover:bg-gray-200 focus:outline-none focus:bg-gray-300"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isHistory && !isAdmin && (
        <History open={isHistory} closeHistoryModal={closeHistoryModal} />
      )}
      {isUploadFile && (
        <FileUpload
          open={isUpload}
          closeUploadFileModal={HandleCloseUploadFile}
          showModal={true}
        />
      )}
      {/* {isConfigure && (
        <ConfigureNewFormat
          open={isConfigure}
          closeConfigureModal={closeConfigureModal}
        />
      )} */}
    </div>
  );
};

export default Header;
