import { IconCloudUpload } from "@tabler/icons-react";
import React, { useState, ChangeEvent, DragEvent, useEffect } from "react";
import ConfirmationPopup from "./ConfirmationPopup";
import Modal from "../../components/Modal";
import axios from "axios";
import WarningPopup from "../../components/WarningPopup";
// import pdf from "./../../assets/pdf-icon.png";
import UploadingProcess from "./UploadingProcess";
import UploadedFileDetails from "./UploadedFileDetails";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useToast } from "../../components/Toast";
import {
  GET_FORMAT_NAME,
  INVOICE_TO_CSV,
  INVOICE_TO_JSON,
  INVOICE_TO_XML,
  UPLOAD_INVOICE,
} from "../../utils/api-details/ApiList";

interface FormatItem {
  INVOICE_FORMAT_ID: number;
  FORMAT_NAME: string;
}

const FileUpload = ({
  open,
  closeUploadFileModal,
  showModal,
}: {
  open: boolean;
  closeUploadFileModal: () => void;
  showModal: boolean;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [isConvertToJson, setIsConvertToJson] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileDetailsModalOpen, setFileDetailsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData | null>(null);
  const [fileFormatErrorMessage, setFileFormatErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isConvertTocsv, setIsConvertTocsv] = useState(false);
  const [isConvertToXml, setIsConvertToXml] = useState(false);
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [formatList, setFormatList] = useState<string[]>([]);
  const [jsonFile, setJsonFile] = useState("");
  const { showToast } = useToast();
  const userType = sessionStorage.getItem("user_type");
  const upload_invoice = UPLOAD_INVOICE;
  const get_format_name = GET_FORMAT_NAME;
  const invoice_to_json = INVOICE_TO_JSON;
  const invoice_to_csv = INVOICE_TO_CSV;
  const invoice_to_xml = INVOICE_TO_XML;

  useEffect(() => {
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + get_format_name}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const results = response?.data?.data?.data?.results;
        // if (results) {
        //   const formatNames: string[] = [];
        //   results.forEach((item: { FORMAT_NAME: string }) => {
        //     if (!formatNames.includes(item.FORMAT_NAME)) {
        //       formatNames.push(item.FORMAT_NAME);
        //     }
        //   });
        //   setFormatList(formatNames);
        // }

        if (results) {
          // Sort results in descending order based on INVOICE_FORMAT_ID
          const sortedResults = (results as FormatItem[]).sort(
            (a, b) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID
          );
          // Extract unique FORMAT_NAME values
          const formatNames: string[] = [];
          sortedResults.forEach((item: { FORMAT_NAME: string }) => {
            if (!formatNames.includes(item.FORMAT_NAME)) {
              formatNames.push(item.FORMAT_NAME);
            }
          });
          setFormatList(formatNames);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  }, [apiUrl, orgID, userID]);

  useEffect(() => {
    if (uploading) {
      const uploadInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(uploadInterval);
            setUploading(false);
            return 100;
          }
          return prev + 10;
        });
      }, 500);
    }
  }, [uploading]);

  const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (): void => {
    setDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setDragging(false);
    if (e.dataTransfer.files.length > 0) {
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.type === "application/pdf") {
        handleFileUpload(file);
      } else {
        setErrorMessage("Invalid file type. Please upload a PDF file.");
      }
    }
  };

  const handleFileUpload = (file: File): void => {
    if (file.type !== "application/pdf") {
      setErrorMessage("Invalid file type. Please upload a PDF file.");
      return;
    }
    setErrorMessage(null);
    if (file.name.includes(" ")) {
      showToast("File name have space so replacing with _", "success");
    }
    setFileName(file.name.replace(/ /g, "_"));
    setProgress(0);
    setUploading(true);
    openModal();
    openFileDetailsModal();

    // Prepare the form data for the API request
    const formData = new FormData();
    formData.append("file", file);
    setFormData(formData);
    // Create an AbortController to handle cancellation
    const controller = new AbortController();
    setAbortController(controller);
    axios

      .post(`${apiUrl + upload_invoice}`, formData, {
        params: {
          user_type: userType === "A" ? "admin" : "user",
          org_id: orgID,
          format_name: selectedOption,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
        signal: controller.signal,
      })
      .then((response) => {
        setJsonFile(response?.data?.filename);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("File upload canceled");
        } else {
          console.error("Error uploading file:", error);
          // setErrorMessage("An error occurred during the file upload.");
          showToast("An error occurred during the file upload", "error");
        }
      })
      .finally(() => {
        setUploading(false);
        setProgress(100);
      });

    // Simulate upload progress
    const uploadInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(uploadInterval);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleCancel = (): void => {
    if (abortController) {
      abortController.abort(); // Abort the ongoing upload
      setUploading(false);
      setProgress(0);
      setFileName(null);
    }
  };

  const handleConvertToJson = async () => {
    if (jsonFile !== undefined) {
      await axios
        .get(`${apiUrl + invoice_to_json}`, {
          params: {
            filename: jsonFile,
            format_name: selectedOption,
            user_id: userID,
            orgid: orgID,
          },
        })
        .then((response) => {
          setIsConvertToJson(true);
        })
        .catch(function (error) {
          console.error("Error:", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            setFileFormatErrorMessage(error.response.data.detail);
            showToast(error.response.data.detail, "error");
          } else {
            showToast("Something went wrong", "error");
          }
          setIsConvertToJson(false);
        });
    } else {
      showToast("Filename is missing", "error");
    }
  };

  const handleClosePopup = () => {
    setFileName(null);
    setIsConvertToJson(false);
    setIsConvertTocsv(false);
    // setIsConvertToCSV(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFileName(null);
  };

  const openFileDetailsModal = () => {
    setFileDetailsModalOpen(true);
  };

  const closeFileDetailsModal = () => {
    setFileDetailsModalOpen(false);
    setFileName(null);
    setFileFormatErrorMessage("");
  };

  const handleChange = (selected: { value: string }) => {
    setSelectedOption(selected.value);
  };

  const handleConvertToCsv = async () => {
    if (jsonFile !== undefined) {
      await axios
        .get(`${apiUrl + invoice_to_csv}`, {
          params: {
            filename: jsonFile,
            format_name: selectedOption,
            user_id: userID,
            orgid: orgID,
          },
        })
        .then((response) => {
          console.log(response);
          setIsConvertTocsv(true);
        })
        .catch(function (error) {
          console.error("Error:", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            setFileFormatErrorMessage(error.response.data.detail);
            showToast(error.response.data.detail, "error");
          } else {
            showToast("Something went wrong", "error");
          }
          setIsConvertTocsv(false);
        });
    } else {
      showToast("Filename is missing", "error");
    }
  };

  const handleConvertToXml = async () => {
    if (jsonFile !== undefined) {
      await axios
        .get(`${apiUrl + invoice_to_xml}`, {
          params: {
            filename: jsonFile,
            format_name: selectedOption,
            user_id: userID,
            orgid: orgID,
          },
        })
        .then((response) => {
          console.log(response);
          setIsConvertToXml(true);
        })
        .catch(function (error) {
          console.error("Error:", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            setFileFormatErrorMessage(error.response.data.detail);
            showToast(error.response.data.detail, "error");
          } else {
            showToast("Something went wrong", "error");
          }
          setIsConvertToXml(false);
        });
    } else {
      showToast("Filename is missing", "error");
    }
  };

  return (
    <div>
      <div className="flex  items-center justify-center h-full w-full">
        {/* {!uploading && !fileName &&( */}
        {!uploading && !fileName && (
          <>
            {showModal ? (
              <Modal
                isOpen={open}
                onClose={closeUploadFileModal}
                title="Upload PDF"
                showCancelButton={false}
                size="w-7/12"
              >
                <UploadContent />
              </Modal>
            ) : (
              <UploadContent />
            )}
          </>
        )}

        {uploading && (
          <UploadingProcess
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            progress={progress}
            handleCancel={handleCancel}
          />
        )}

        {fileName &&
          !uploading &&
          !isConvertToJson &&
          !isConvertTocsv &&
          !isConvertToXml && (
            // !errorMessage && (
            <UploadedFileDetails
              isFileDetailsModalOpen={isFileDetailsModalOpen}
              closeFileDetailsModal={closeFileDetailsModal}
              fileName={fileName}
              handleConvertToJson={handleConvertToJson}
              handleConvertToCsv={handleConvertToCsv}
              handleConvertToXml={handleConvertToXml}
              selectedOption={selectedOption}
            />
          )}
      </div>

      <ConfirmationPopup
        isConvertToJson={isConvertToJson}
        isConvertTocsv={isConvertTocsv}
        isConvertToXml={isConvertToXml}
        onClose={handleClosePopup}
        uploadedFileName={fileName ? fileName : ""}
        format_name={selectedOption}
      />

      {fileFormatErrorMessage && (
        <WarningPopup
          isOpen={open}
          onClose={closeFileDetailsModal}
          title=" "
          showCancelButton={true}
          size="w-2/5"
        >
          <div className="p-3 text-center">
            <span className=" text-lg">{fileFormatErrorMessage}</span>
          </div>
        </WarningPopup>
      )}
    </div>
  );

  function UploadContent() {
    return (
      <div className={`${showModal ? "" : "w-full"}`}>
        <div className="flex flex-col w-52 max-w-xs">
          <label className="font-bold mb-2 text-gray-700" htmlFor="dropdown">
            Payable To:
          </label>
          {/* <div className="border border-gray-300 rounded-lg relative"> */}
          <div>
            <Dropdown
              options={formatList}
              onChange={handleChange}
              value={selectedOption}
              placeholder="Select a format"
            />
          </div>
        </div>
        <div
          className={`w-full max-w-4xl mt-8 bg-sky-50 p-4 border rounded-lg ${
            dragging ? "border-blue-500" : "border-gray-300 border-2"
          } ${selectedOption ? "" : "opacity-55 pointer-events-none"}`}
          style={{ height: `${showModal ? "350px" : "250px"}` }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            id="file-upload"
            className="hidden"
            onChange={handleFileChange}
          />
          <label
            htmlFor="file-upload"
            className="flex flex-col items-center justify-center h-full cursor-pointer"
          >
            <IconCloudUpload stroke={2} color="#3b82f6" className="h-14 w-14" />
            <span className="mt-6 font-medium">
              Choose a file or drag & drop it here.
            </span>
            <span className="text-gray-500">PDF formats, up to 1 MB</span>
            <button
              type="button"
              onClick={() => document.getElementById("file-upload")?.click()}
              className="mt-6 w-44 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Browse File
            </button>
            {errorMessage && (
              <p className="mt-4 text-red-500">{errorMessage}</p>
            )}
          </label>
        </div>
      </div>
    );
  }
};

export default FileUpload;
