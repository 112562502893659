import { useState } from "react";
import { useNavigate } from "react-router-dom";
import loginPagePic from "./../../assets/LoginScreenGraphic.svg";
import productIcon from "./../../assets/productIcon.svg";
import axios from "axios";
import { RESET_LINK } from "../../utils/api-details/ApiList";
import { useToast } from "../../components/Toast";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [orgId, setOrgId] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const reset_link = RESET_LINK;
  const { showToast } = useToast();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    axios
      .get(`${apiUrl + reset_link}`, {
        params: {
          username: username,
          orgid: orgId,
        },
      })
      .then((response) => {
        navigate("/");
      })
      .catch(function (error) {
        console.log(error);
        showToast("Something went wrong", "error");
      });
  };

  return (
    <div className="grid grid-cols-12 h-screen">
      <div className="col-span-7 relative">
        <img
          src={loginPagePic}
          alt="LoginPic"
          className=" p-4 w-screen h-screen"
        />
      </div>
      <div className="flex col-span-5 p-2 items-center ml-16 min-h-screen ">
        <div className="w-full max-w-md p-8">
          <div className="flex justify-center">
            <img
              src={productIcon}
              alt="Invoicify"
              className="w-64 max-w-full"
            />
          </div>
          <h2 className="mt-14 text-2xl font-bold  font-Inter text-center text-gray-900">
            Forgot Your Password?
          </h2>
          <h6 className="mb-12 text-sm font-bold  font-Inter text-center text-gray-500">
            No worries! Enter your email address to receive a password reset
            link
          </h6>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <div>
                <label
                  htmlFor="orgId"
                  className="block text-sm text-left font-medium font-Inter text-gray-700"
                >
                  OrgId
                </label>
                <input
                  id="OrgId"
                  name="OrgId"
                  value={orgId}
                  placeholder="OrgId"
                  onChange={(e) => setOrgId(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="email"
                  className="block text-sm text-left font-medium font-Inter text-gray-700"
                >
                  Username
                </label>
                <input
                  id="email"
                  name="email"
                  value={username}
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-700 text-white font-semibold  font-Inter rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              >
                Send Reset Link
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
