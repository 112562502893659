import { IconCloudUpload, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import axios from "axios";
import { UPLOAD_INVOICE } from "../../../../utils/api-details/ApiList";

interface FileExtractedData {
  [key: string]: any;
}

interface BuyerSupplierData {
  supplierName?: string;
  buyerName?: string;
}
const UploadInvoice = ({
  setHasChanges,
  uploadInvoicefiles,
  setUploadInvoiceFiles,
  isError,
  fileExtractedData,
  setFileExtractedData,
  setDictionaryMappingRequired,
  setBuyerSupplier,
}: {
  setHasChanges: (value: boolean) => void;
  uploadInvoicefiles: File[];
  setUploadInvoiceFiles: React.Dispatch<React.SetStateAction<File[]>>;
  isError: boolean;
  fileExtractedData: FileExtractedData;
  setFileExtractedData: React.Dispatch<React.SetStateAction<FileExtractedData>>;
  setDictionaryMappingRequired: (value: string) => void;
  setBuyerSupplier: React.Dispatch<React.SetStateAction<BuyerSupplierData>>;
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [extractedData, setExtractedData] = useState<string[]>([]);
  // const [fileExtractedData, setFileExtractedData] = useState({});
  const [fileData, setFileData] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;
  const userType = sessionStorage.getItem("user_type");
  const upload_invoice = UPLOAD_INVOICE;

  useEffect(() => {
    setHasChanges(uploadInvoicefiles.length > 0);
  }, [setHasChanges, uploadInvoicefiles]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const acceptedFiles = Array.from(event.dataTransfer.files).filter(
      (file: File) => ["application/pdf"].includes(file.type)
    );
    setUploadInvoiceFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    setExtractedData(extractDataFromFiles(acceptedFiles));
    uploadFilesToAPI(acceptedFiles);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedFiles = Array.from(event.target.files || []).filter(
      (file: File) => ["application/pdf"].includes(file.type)
    );
    setUploadInvoiceFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    setExtractedData(extractDataFromFiles(acceptedFiles));
    setHasChanges(true);
    uploadFilesToAPI(acceptedFiles);
  };

  const handleButtonClick = () => {
    const fileInput = document.querySelector(
      "input[type='file']"
    ) as HTMLInputElement;
    fileInput.click();
  };

  const handleCancel = (fileToRemove: File) => {
    setUploadInvoiceFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
    setExtractedData((prevData) =>
      prevData.filter((data) => !data.includes(fileToRemove.name))
    );
  };

  const extractDataFromFiles = (files: File[]) => {
    return files.map((file) => `Data from ${file.name}`);
  };

  const extractKeys = (obj: Record<string, any>, parentKey = "") => {
    let keys: string[] = [];
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (Array.isArray(obj[key])) {
          // keys.push(`${newKey}.[${obj[key].length}]`);
          keys.push(`${newKey}`);
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          keys = keys.concat(extractKeys(obj[key], newKey));
        } else {
          keys.push(newKey);
        }
      }
    }
    return keys;
  };

  const uploadFilesToAPI = async (files: File[]) => {
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });
      const response = await axios.post(
        `${apiUrl + upload_invoice}`,
        formData,
        {
          params: {
            user_type: userType === "A" ? "admin" : "user",
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const result = response?.data?.message;
      setFileData(result);
      setBuyerSupplier({
        supplierName: result.payable_to,
        buyerName: result.buyer_name,
      });
      const dictionaryMapping = response?.data?.dictionary_mapping_required;
      setDictionaryMappingRequired(dictionaryMapping);
      const data = extractKeys(result);
      setFileExtractedData(data);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  return (
    <div className="flex flex-wrap justify-between gap-6">
      <div className="w-full h-auto p-4 bg-white border-2 shadow-md rounded-lg">
        <h2 className="font-semibold text-xl text-blue-700">UPLOAD FILE</h2>
        <hr className="my-2" />
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          // className="border-2 border-dashed border-gray-300 p-4 rounded-lg text-center"
          className=" border-gray-300 p-4 h-72 rounded-lg text-center bg-gray-100"
        >
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="hidden"
            multiple
          />
          <div className="flex flex-col justify-center items-center">
            <IconCloudUpload stroke={2} color="#3b82f6" className="h-14 w-14" />
            <p className="mt-2 text-gray-700">
              Choose a file or Drag & drop it here
            </p>
            <button
              onClick={handleButtonClick}
              className="mt-4 w-36 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
            >
              Browse Files
            </button>
          </div>
        </div>

        {/* File List */}
        <div className="mt-6">
          {uploadInvoicefiles.map((file, index) => (
            <div
              key={index}
              className="relative flex items-center justify-between p-3 mb-2 border-2 border-gray-200 rounded-lg bg-gray-100"
            >
              <div className="flex items-center">
                <p className="text-gray-700">{file.name}</p>
              </div>
              <IconX
                onClick={() => handleCancel(file)}
                stroke={1}
                className="cursor-pointer text-gray-700 hover:text-red-500 transition-colors"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Extracted Fields Section */}
      <div className="w-full  p-6 bg-white border-2 shadow-md rounded-lg overflow-auto">
        <h2 className="font-semibold text-xl">Extracted Fields</h2>
        <hr className="my-2" />
        <div>
          {uploadInvoicefiles.length > 0 ? (
            <pre className="p-4 bg-gray-50 rounded-lg whitespace-pre-wrap overflow-x-auto text-sm">
              {!fileData ||
              (Object.keys(fileData).length === 0 &&
                fileData.constructor === Object)
                ? "Loading..."
                : JSON.stringify(fileData, null, 2)}
            </pre>
          ) : (
            <p className="text-center text-gray-500">No data</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadInvoice;
