import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { VISIT_COUNT } from '../utils/api-details/ApiList';

const VisitCountContext = createContext<number>(0); 

export const useVisitCount = () => {
  return useContext(VisitCountContext);
};

export const VisitCountProvider = ({ children }: { children: React.ReactNode }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [visitCount, setVisitCount] = useState<number>(0);
 const visit_count = VISIT_COUNT;

  useEffect(() => {
    axios
      .get("http://ip-api.com/json/")
      .then((response) => {
        const data = response.data;
        getVisitCount(data);
      })
      .catch((error) => {
        console.error("Error fetching IP:", error);
        getVisitCount();
      });
  }, []);

  const getVisitCount = (data?: string) => {
    const visitInfo = {status:"success"}
    const info = data ? data :visitInfo
    axios
      .post(`${apiUrl + visit_count}`, info)
      .then((response) => {
        const count = response?.data?.visit_count;
        setVisitCount(count);
      })
      .catch((error) => {
        console.error("Error fetching visit count:", error);
      });
  };

  return (
    <VisitCountContext.Provider value={visitCount}>
      {children}
    </VisitCountContext.Provider>
  );
};
