import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import axios from "axios";
import EditCsv from "./EditCsv";
import CsvDataDisplay from "../../../components/CsvDataDisplay";
import { IconTriangleInvertedFilled } from "@tabler/icons-react";
import CalculatorPage from "../../../components/Calculator";
import { useCsvFileDownloader } from "../../../components/Download";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useToast } from "../../../components/Toast";
import {
  INVOICE_VERSION_BY_INVOICE_NAME,
  VIEW_CSV,
} from "../../../utils/api-details/ApiList";

export const ViewCsv = ({
  isCsvViewModalOpen,
  onClose,
  uploadedFileName,
  format_name,
  latestVersion,
  invoiceType,
}: {
  isCsvViewModalOpen?: boolean;
  onClose: () => void;
  uploadedFileName?: string;
  format_name?: string;
  latestVersion?: number;
  invoiceType?: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [csvData, setCSVData] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const downloadFile = useCsvFileDownloader(apiUrl, format_name);
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [selectedVersion, setSelectedVersion] = useState<string>("");
  const [versionList, setVersionList] = useState<string[]>([]);
  const [error, setError] = useState("");
  const { showToast } = useToast();
  const view_csv = VIEW_CSV;
  const invoice_version_by_invoice_name = INVOICE_VERSION_BY_INVOICE_NAME;

  useEffect(() => {
    // setSelectedOption("");
    if (isCsvViewModalOpen) {
      setIsModalOpen(true);
      previewFile(invoiceType ? invoiceType : "appended");
      getVersionList("");
    } else {
      setIsModalOpen(false);
      setSelectedOption("");
    }
    if (uploadedFileName) {
      const fileName = uploadedFileName.split(".")[0];
      setFileName(fileName);
    }
    if (selectedVersion) {
      previewFile(
        selectedOption === "original_csv" || invoiceType === "original"
          ? "original"
          : "appended"
      );
      getVersionList(
        selectedOption === "original_csv" ? "original_csv" : "appended_csv"
      );
    }
  }, [apiUrl, fileName, isCsvViewModalOpen, uploadedFileName, selectedVersion]);

  const previewFile = (version: string) => {
    if (fileName !== "") {
      axios
        .get(`${apiUrl + view_csv}/${fileName + ".csv"}`, {
          params: {
            format_name: format_name,
            version:
              selectedVersion || latestVersion
                ? selectedVersion || latestVersion
                : 0,
            type: version,
            orgid: orgID,
            user_id: userID,
          },
        })
        .then((response) => {
          setCSVData(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setError(error?.response?.data?.detail);
          const errorMsg = error?.response?.data?.detail;
          showToast(errorMsg ? errorMsg : "Something went wrong", "error");
          setCSVData("");
        });
    }
  };

  const getVersionList = (optionSelected: string) => {
    const payload = {
      invoice_name: fileName + ".csv",
      format_name: format_name,
      org_id: orgID,
      user_id: userID,
    };
    if (fileName !== "") {
      axios
        .post(`${apiUrl + invoice_version_by_invoice_name}`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let allVersions: string[] = [];
          if (response?.data?.data?.results) {
            // Filter versions based on selected option
            if (
              optionSelected === "original_csv" ||
              invoiceType === "original"
            ) {
              const originalData = response.data.data.results.filter(
                (item: { FINVOICE_TYPE: string }) =>
                  item.FINVOICE_TYPE === "ORIGINAL" ||
                  item.FINVOICE_TYPE === "original"
              );
              allVersions =
                originalData.length > 0
                  ? originalData[0].all_version.split(",")
                  : [];
            } else if (
              optionSelected === "appended_csv" ||
              optionSelected === "" ||
              invoiceType === "appended"
            ) {
              const appendedData = response.data.data.results.filter(
                (item: { FINVOICE_TYPE: string }) =>
                  item.FINVOICE_TYPE === "APPENDED" ||
                  item.FINVOICE_TYPE === "appended"
              );
              allVersions =
                appendedData.length > 0
                  ? appendedData[0].all_version.split(",")
                  : [];
            }
          }
          setVersionList(allVersions);
        })
        .catch((error) => {
          console.error(error);
          showToast(
            "Something went wrong while getting invoice version!",
            "error"
          );
        });
    }
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleClosePopup = () => {
    setIsEditModalOpen(false);
    setIsModalOpen(false);
    if (onClose) onClose();
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleSave = (updatedData: any) => {
    setCSVData(updatedData);
    setIsEditModalOpen(false);
    getVersionList(selectedOption ?? "");
  };

  const handleDownload = async () => {
    await downloadFile(uploadedFileName, selectedOption, selectedVersion,latestVersion,invoiceType)
      .then(() => {
        console.log("csv downloaded successfully");
      })
      .catch((error) => {
        console.error("Error downloading xml:", error?.response?.statusText);
        const errorMsg = error?.response?.statusText
          ? "File " + error?.response?.statusText
          : "Something went wrong!";
        showToast(errorMsg, "error");
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    getVersionList(value);
    switch (value) {
      case "original_csv":
        previewFile("original");
        break;
      case "appended_csv":
        previewFile("appended");
        break;
      default:
        setSelectedOption("");
        break;
    }
  };

  const handleSelectedVersion = (selected: { value: string }) => {
    // const value = event.target.value;
    const value = selected.value;
    setSelectedVersion(value);
    console.log("selected version", value);
    setError("");
  };
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (onClose) onClose();
        }}
        title={fileName + " - CSV"}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col ">
          <div className="mt-auto flex justify-end space-x-4">
            <div className="border border-gray-300 rounded-lg relative">
              <select
                className="appearance-none px-6 py-2 border border-blue-700 text-blue-600 rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
                id="dropdown"
                value={selectedOption}
                onChange={handleChange}
              >
                <option value="">View Option</option>
                {selectedOption === "original_csv" || invoiceType === "original" ? (
                  <option value="appended_csv">Appended CSV</option>
                ) : (
                  <option value="original_csv">Original CSV</option>
                )}
              </select>
              <div className="pointer-events-none absolute inset-y-0 -right-1 flex items-center px-2">
                {/* <IconChevronDown stroke={1} color="#3b82f6" /> */}
                <IconTriangleInvertedFilled className="w-4 h-2 text-gray-400 " />
              </div>
            </div>
            <Dropdown
              options={versionList}
              onChange={handleSelectedVersion}
              value={selectedVersion}
              placeholder="Select Version"
              controlClassName="w-full px-4 py-2 !border-1 !border-blue-700 !rounded-lg !text-blue-600 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              menuClassName="border-2 border-blue-500 rounded-lg bg-white shadow-lg max-h-60 overflow-y-auto"
              arrowClassName="mt-1"
            />
            <button
              type="button"
              className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              onClick={handleEdit}
            >
              Edit
            </button>
            <button
              type="button"
              className="px-4 py-2 w-32  bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleDownload}
            >
              Download
            </button>
            <CalculatorPage />
          </div>
        </div>
        <h1 className="font-bold text-sm mb-4 mt-1">
          {selectedOption === "original_csv" || invoiceType === "original"
            ? "Original CSV"
            : "Appended CSV"}
        </h1>
        <div
          className="h-96  items-center justify-between mt-8 overflow-y-scroll "
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
          }}
        >
          {/* {error && error}
          {!error && ( */}
          <div>
            {csvData ? (
              <CsvDataDisplay csvData={csvData} />
            ) : error ? (
              error
            ) : (
              "Loading..."
            )}
          </div>
          {/* )} */}
        </div>
      </Modal>

      <EditCsv
        isEditModalOpen={isEditModalOpen}
        onClose={handleClosePopup}
        onCancel={handleCancel}
        handleSave={handleSave}
        fileName={fileName}
        selectedOption={selectedOption}
        format_name={format_name}
        orgID={orgID ?? ""}
        userID={userID ?? ""}
        selectedVersion={selectedVersion}
      />
    </div>
  );
};

export default ViewCsv;
