import { useState } from "react";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import loginPagePic from "./../../assets/LoginScreenGraphic.svg";
import { useAuth } from "../../routes/AuthContext";
// import productIcon from "./../../assets/productIcon.svg";
import productIcon from "./../../assets/Logo_v3-01.png";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [orgId, setOrgId] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login, errorMessage } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const success = await login(email, password, orgId);
    if (success) {
      const data = sessionStorage.getItem("reset_complete");
      if (data === "N") {
        navigate("/resetPassword");
      } else {
        navigate("/dashboard");
      }
    } else {
      console.log("Login failed");
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgotPassword");
  };
  return (
    <div className="grid grid-cols-12 h-screen">
      <div className="col-span-7 relative">
        <img
          src={loginPagePic}
          alt="LoginPic"
          className=" p-4 w-screen h-screen"
        />
      </div>
      <div className="flex col-span-5 p-2 items-center ml-16 min-h-screen ">
        <div className="w-full max-w-md p-7">
          <div className="flex justify-center">
            <img
              src={productIcon}
              alt="Invoicify"
              className="w-64 max-w-full"
            />
          </div>
          <h6 className=" text-sm font-bold  font-Inter text-center text-gray-500">
            Welcome back
          </h6>
          <h2 className="mb-12 text-2xl font-bold  font-Inter text-center text-gray-900">
            Login in to your Account
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="orgId"
                className="block text-sm text-left font-medium font-Inter text-gray-700"
              >
                OrgId
              </label>
              <input
                id="OrgId"
                name="OrgId"
                value={orgId}
                placeholder="OrgId"
                onChange={(e) => setOrgId(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm text-left font-medium font-Inter text-gray-700"
              >
                Username
              </label>
              <input
                id="email"
                name="email"
                value={email}
                placeholder="Username"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm text-left font-medium font-Inter text-gray-700"
              >
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 hover:text-gray-700"
                >
                  {showPassword ? (
                    <IconEye stroke={1} />
                  ) : (
                    <IconEyeOff stroke={1} />
                  )}
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="h-4 w-4 text-blue-700 focus:ring-blue-700 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900 font-Inter"
                >
                  Remember me
                </label>
              </div>
              <div className="text-sm">
                <div
                  onClick={handleForgotPassword}
                  className="font-medium text-blue-700 hover:text-blue-500 font-Inter"
                >
                  Forgot your password?
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-700 text-white font-semibold  font-Inter rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              >
                Log In
              </button>
              {errorMessage && (
                <p className="mt-2 text-red-500">{errorMessage}</p>
              )}
            </div>
            <div className="text-sm text-center">
              <span className="text-gray-600  font-Inter">
                Don't have an account?
              </span>
              <a
                href="/"
                className="font-medium  font-Inter text-blue-700 hover:text-blue-500"
              >
                Create an account
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
