import React, { createContext, useState, useContext, ReactNode } from 'react';
import * as Toast from '@radix-ui/react-toast';

interface ToastContextType {
    showToast: (message: string, type: 'success' | 'error') => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<'success' | 'error'>('success'); 

  // Function to show toast
  const showToast = (message: string, type: 'success' | 'error') => {
    setMessage(message);
    setType(type);  
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000); 
  };

  const toastBackgroundColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';

  return (
    <ToastContext.Provider value={{ showToast }}>
    <Toast.Provider>
      {children}
      <Toast.Root
        open={open}
        onOpenChange={setOpen}
        className={`fixed top-5 right-5 ${toastBackgroundColor} text-white p-4 rounded-lg shadow-lg z-50`}
      >
        <Toast.Title className="font-semibold p-2">{message}</Toast.Title>
        <Toast.Close className="absolute top-2 right-2 text-white cursor-pointer">✖</Toast.Close>
      </Toast.Root>
      <Toast.Viewport />
    </Toast.Provider>
  </ToastContext.Provider>
  );
};
