import LoginPage from "../pages/login/LoginPage";
import DashboardPage from "../pages/dashboard/Dashboard";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import Home from "../pages/home/Home";
import ResetPassword from "../pages/login/ResetPassword";
import ForgotPassword from "../pages/login/ForgotPassword";
import MonitorActions from "../pages/adminAction/monitoring/MonitorActions";
import Stats from "../pages/adminAction/stats/Stats";
import CreateFormat from "../pages/adminAction/createNewFormat/CreateFormat";
import UpdateFormat from "../pages/adminAction/editFormat/UpdateFormat";
import FormatDetails from "../pages/adminAction/formatDetails/FormatDetails";

const AppRoutes = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resetPassword" element={<ResetPassword />}/>
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/create-format" element={<CreateFormat />} />
        <Route path="/update-format" element={<UpdateFormat />} />
        <Route path="/monitoring" element={<MonitorActions />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/FormatDetails" element={<FormatDetails />} />

        {/* <Route path="/history" element={<History />} /> */}
        {/* <Route path="/dashboard" element={<ProtectedRoute element={<DashboardPage />} />} /> */}
      </Routes>
    </AuthProvider>
  );
};

export default AppRoutes;
