// import * as RadioGroup from "@radix-ui/react-radio-group";

// const RadioGroupComponent = ({
//   selectedValue,
//   handleValueChange,
//   labels,
//   // values
// }: {
//   selectedValue: string;
//   handleValueChange: (value: string) => void;
//   labels: string[];
//   // values?:string
// }) => {
//   return (
//     <RadioGroup.Root
//       value={selectedValue}
//       onValueChange={handleValueChange}
//       className="flex space-x-8"
//     >
//       {labels.map((label) => (
//         <RadioGroup.Item
//           key={label}
//           value={label}
//           className="relative flex items-center cursor-pointer"
//         >
//           <div className="w-5 h-5 flex items-center justify-center border-2 border-gray-300 rounded-full mr-4">
//             <RadioGroup.Indicator className="bg-blue-500 rounded-full w-3 h-3" />
//           </div>
//           <span className=" font-medium text-gray-700">{label}</span>
//         </RadioGroup.Item>
//       ))}
//     </RadioGroup.Root>
//   );
// };

// export default RadioGroupComponent;
import * as RadioGroup from "@radix-ui/react-radio-group";

const RadioGroupComponent = ({
  selectedValue,
  handleValueChange,
  levels,
}: {
  selectedValue: string;
  handleValueChange: (value: string) => void;
  levels: { Label: string; value: string }[];
}) => {
  return (
    <RadioGroup.Root
      value={selectedValue}
      onValueChange={handleValueChange}
      className="flex space-x-8"
    >
      {levels.map((level) => (
        <RadioGroup.Item
          key={level.value}  // Use 'value' as the key for better uniqueness
          value={level.value}  // Use 'value' as the value for the radio button
          className="relative flex items-center cursor-pointer"
        >
          <div className="w-5 h-5 flex items-center justify-center border-2 border-gray-300 rounded-full mr-4">
            <RadioGroup.Indicator className="bg-blue-500 rounded-full w-3 h-3" />
          </div>
          <span className=" font-medium text-gray-700">{level.Label}</span> {/* Display the Label */}
        </RadioGroup.Item>
      ))}
    </RadioGroup.Root>
  );
};

export default RadioGroupComponent;
