import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { ToastProvider } from './components/Toast';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n/i18n';
import { VisitCountProvider } from './contexts/VisitCountContext';

const App = () => {
  return (
    <I18nextProvider i18n={i18next}>
      <ToastProvider>
        <VisitCountProvider> 
          <Router>
            <AppRoutes />
          </Router>
        </VisitCountProvider>
      </ToastProvider>
    </I18nextProvider>
  );
};

export default App;
