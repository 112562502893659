import Carousel from "../../components/Carousel";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import profilePic from "./../../assets/profilePic.jpg";
import SupportedInvoice from "../home/SupportedInvoice";
import productIcon from "./../../assets/productIcon.svg";
import History from "../history/History";
import { IconMenu2 } from "@tabler/icons-react";
import FileUpload from "./FileUpload";
import axios from "axios";
import { useToast } from "../../components/Toast";
import { GET_INVOICE_COUNT_BY_ORGID } from "../../utils/api-details/ApiList";

const DashboardPage = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [isActionVisible, setIsActionVisible] = useState(false);
  const [isHistory, setIsHistory] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [count, setCount] = useState(0);
  const { showToast } = useToast();
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const get_invoice_count_by_orgid = GET_INVOICE_COUNT_BY_ORGID;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
        setIsActionVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    const retrievedDataString = sessionStorage.getItem("user_type");
    if (retrievedDataString === "A") {
      setIsAdmin(true);
    }
    getInvoiceCount();
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getInvoiceCount = () => {
    axios
      .get(`${apiUrl + get_invoice_count_by_orgid}`, {
        params: {
          org_id: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        const count =
          response?.data?.data?.data?.results[0].Invoice_count_orgid;
        setCount(count);
      })
      .catch((error) => {
        console.log(error);
        showToast("Something went wrong!", "error");
      });
  };

  const toggleAction = () => {
    setIsActionVisible(!isActionVisible);
  };

  const handleLogout = () => {
    navigate("/");
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsActionVisible(false);
  };

  const handleOptionClick = (action: string) => {
    switch (action) {
      case "History":
        setIsHistory(true);
        break;
      case "Upload File":
        setIsUploadFile(true);
        setIsUpload(true);
        break;
      case "Configure New Format":
        // setIsConfigure(true);
        navigate("/create-format");
        break;
      case "Update Configure":
        navigate("/update-format");
        break;
      case "Monitoring":
        navigate("/monitoring");
        break;
      case "Stats":
        navigate("/stats");
        break;
      case "Activate/Deactivate":
        navigate("/FormatDetails");
        break;
      default:
        break;
    }
    setIsActionVisible(false);
  };

  const closeHistoryModal = () => {
    setIsHistory(false);
  };

  const HandleCloseUploadFile = () => {
    setIsUpload(false);
  };

  return (
    <div className="p-4">
      <div className="flex flex-wrap">
        <div className="flex-shrink-0 mb-2 w-5/12 pr-0 h-full">
          <div className="bg-white shadow-lg rounded-lg p-6 mb-4 card border-2">
            <div className="flex items-start space-x-2">
              <img
                src={productIcon}
                alt="Invoicify"
                className="w-36 max-w-full"
              />
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 mb-2 w-2/12 h-full ">
          <div className="bg-blue-700 shadow-lg text-white rounded-lg p-6  mb-4">
            <div className="flex space-x-2">
              <span className="text-sm mt-1">INVOICES PROCESSED</span>
              <span className="text-xl font-bold ml-0">:</span>
              <h1 className="text-xl font-bold font-Inter text-center">
                {count}
              </h1>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 mb-2 w-5/12 h-full ">
          <div className="bg-white shadow-lg rounded-lg p-3 mb-4 card border-2">
            <div className="flex justify-end relative">
              <IconMenu2
                stroke={2}
                onClick={toggleAction}
                className="mt-3 mr-1 cursor-pointer"
              />
              {/* Action Menu */}
              {isActionVisible && (
                <div className="absolute right-0 mt-14 w-48 bg-white border rounded-md shadow-lg z-10">
                  <div className="py-1" ref={dropdownRef}>
                    {!isAdmin ? (
                      <>
                        <button
                          className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                          onClick={() => handleOptionClick("History")}
                        >
                          History
                        </button>
                        <button
                          className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                          onClick={() => handleOptionClick("Upload File")}
                        >
                          Upload File
                        </button>
                      </>
                    ) : (
                      <div>
                        <button
                          className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                          onClick={() =>
                            handleOptionClick("Configure New Format")
                          }
                        >
                          Configure New Format
                        </button>
                        <button
                          className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                          onClick={() => handleOptionClick("Update Configure")}
                        >
                          Update Existing Format
                        </button>
                        <button className="block w-full text-left px-4 py-2 text-gray-400 cursor-not-allowed hover:bg-gray-100">
                          Request New User
                        </button>
                        <button className="block w-full text-left px-4 py-2 text-gray-400 cursor-not-allowed hover:bg-gray-100">
                          Block User
                        </button>
                        <button
                          className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                          onClick={() => handleOptionClick("Monitoring")}
                        >
                          Monitoring
                        </button>
                        <button
                          className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                          onClick={() => handleOptionClick("Stats")}
                        >
                          Stats
                        </button>
                        <button
                          className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
                          onClick={() =>
                            handleOptionClick("Activate/Deactivate")
                          }
                        >
                          Activate/Deactivate Format
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <img
                src={profilePic}
                alt="Profile"
                className="w-12 h-12 rounded-full cursor-pointer"
                onClick={toggleDropdown}
              />
              {isDropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute right-0 mt-14 w-48 bg-white border rounded-md shadow-lg z-10"
                >
                  <button
                    className="w-full text-left px-6 py-2 hover:bg-gray-200 focus:outline-none focus:bg-gray-300"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-100 w-full  h-80 shadow-lg rounded-lg p-6 mb-2 card">
        <Carousel />
      </div>
      {/* <SupportedInvoice isIconClick={true} />
     <FileUpload open={true} closeUploadFileModal={HandleCloseUploadFile} showModal={false}/> */}
      {isAdmin ? (
        <SupportedInvoice isIconClick={true} />
      ) : (
        // <div className="flex justify-between w-full">
        //   <div className="w-1/2 p-2 mt-10">
        //     <SupportedInvoice isIconClick={true} />
        //   </div>
        //   <div className="w-1/2 p-2">
        //     <FileUpload
        //       open={true}
        //       closeUploadFileModal={HandleCloseUploadFile}
        //       showModal={false}
        //     />
        //   </div>
        // </div>
        <SupportedInvoice isIconClick={true} />
      )}
      {isHistory && !isAdmin && (
        <History open={isHistory} closeHistoryModal={closeHistoryModal} />
      )}
      {isUploadFile && (
        <FileUpload
          open={isUpload}
          closeUploadFileModal={HandleCloseUploadFile}
          showModal={true}
        />
      )}
    </div>
  );
};

export default DashboardPage;
