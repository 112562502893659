import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Header from "../../dashboard/Header";
import axios from "axios";
import parse from "html-react-parser";
import { useToast } from "../../../components/Toast";
import { VIEW_MONITORING_FILE } from "../../../utils/api-details/ApiList";

const MonitorActions = () => {
  const [selectedOption, setSelectedOption] = useState("log");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState("");
  const [subData, setSubData] = useState("");
  const { showToast } = useToast();
  const orgID = sessionStorage.getItem("orgId");
  const containerRef = useRef<HTMLDivElement | null>(null);
  const view_file = VIEW_MONITORING_FILE;
  
  const options = [
    { value: "log", label: "Log" },
    { value: "mapping", label: "Mapping" },
    { value: "dictionary", label: "Dictionary" },
    { value: "csv", label: "Output (CSV)" },
    { value: "json", label: "Output (JSON)" },
    { value: "xml", label: "Output (XML)" },
  ];

  useEffect(() => {
    handleViewfile(selectedOption, "");
  }, [selectedOption]);

  useEffect(() => {
    bindToggleFunctionality();
  }, [data]);

  // Fetching file data based on type
  const handleViewfile = (type: string, filePath: string) => {
    axios
      .get(`${apiUrl + view_file}`, {
        params: {
          output_type: type,
          file_path: filePath,
          org_id: orgID,
        },
      })
      .then((response) => {
        setData(response?.data);
        if (filePath !== "") {
          setSubData(response?.data);
        }
      })
      .catch((error) => {
        console.log("error", error?.response?.data?.detail);
        const errorMsg = error?.response?.data?.detail;
        showToast(errorMsg ? errorMsg : "Something went wrong!", "error");
      });
  };

  const handleChange = (option: { value: string }) => {
    const val = option.value;
    setSelectedOption(val);
    handleViewfile(val, "");
  };

  const handleLinkClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.tagName === "A") {
      event.preventDefault();
      const url = target.getAttribute("href");
      if (url) {
        const urlParams = new URLSearchParams(url.split("?")[1]);
        const filePath = urlParams.get("file_path");
        const type = urlParams.get("output_type");
        if (filePath && type) {
          handleViewfile(type, filePath);
        }
      }
    }
  };

  const handleBack = () => {
    handleViewfile(selectedOption, "");
    setSubData("");
  };

  // Folder Toggle Logic
  const bindToggleFunctionality = () => {
    if (containerRef.current) {
      const toggleElements =
        containerRef.current.querySelectorAll(".folder-toggle");
      toggleElements.forEach((element) => {
        element.addEventListener("click", () => {
          const folderContent = element.nextElementSibling;
          if (folderContent) {
            const folderContentAsHTMLElement = folderContent as HTMLElement;
            if (folderContentAsHTMLElement?.style) {
              if (
                folderContentAsHTMLElement.style.display === "none" ||
                folderContentAsHTMLElement.style.display === ""
              ) {
                folderContentAsHTMLElement.style.display = "block";
                element.innerHTML = element.innerHTML.replace("➕", "➖");
              } else {
                folderContentAsHTMLElement.style.display = "none";
                element.innerHTML = element.innerHTML.replace("➖", "➕");
              }
            } else {
              console.warn(
                "Could not access the style property of folder content:",
                folderContentAsHTMLElement
              );
            }
          } else {
            console.warn("No folder content found for this element:", element);
            showToast("The folder does not contain any files.", "error");
          }
        });
      });
    }
  };

  return (
    <div className="bg-white" style={{ padding: "0px !important" }}>
      <Header />
      <div className="shadow-sm rounded-lg mb-4 card min-h-[33rem] h-auto">
        <div className="px-4">
          <div className="flex justify-between items-center w-full">
            {subData !== "" && (
              <button
                onClick={handleBack}
                className="px-4 py-2 rounded bg-blue-600 text-white"
              >
                Back
              </button>
            )}
            <div className="flex flex-col items-start ml-auto">
              <h2 className="text-blue-700 font-semibold mt-2">View for</h2>
              <Dropdown
                options={options}
                onChange={handleChange}
                value={selectedOption}
                placeholder="Select view"
                className="p-1 w-48"
                controlClassName="react-dropdown-control"
                menuClassName="react-dropdown-menu max-h-48 overflow-y-auto"
              />
            </div>
          </div>
          <div onClick={handleLinkClick} ref={containerRef}>
            {data !== "" ? (
              parse(data)
            ) : (
              <div className="text-center font-semibold text-sm p-10">
                No Data
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonitorActions;
