import { useTranslation } from 'react-i18next';
import Modal from "../../../../components/Modal";

const ConfirmationLookupConfig = ({
  open,
  onClose,
  setIsConfigureLookup,
  setCurrentStep,
  currentStep,
  isEdit
}: {
  open: boolean;
  onClose: () => void;
  setIsConfigureLookup: (value: boolean) => void;
  setCurrentStep: (value: number) => void;
  currentStep: number;
  isEdit?: boolean;
}) => {
    // const [t] = useTranslation('commonForm')
    // const { t, i18n } = useTranslation();
    const {t} = useTranslation("commonForm");

  const handleYes = () => {
    setIsConfigureLookup(true);
    onClose();
  };
  const handleNo = () => {
    setIsConfigureLookup(false);
    setCurrentStep(currentStep + 1);
    onClose();
  };
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title="Configure Lookup"
      showCancelButton={false}
      size="w-5/12"
      isCrossIcon={false}
    >
      <div className="max-h-96">
        {!isEdit ? 
        'Would you like to enable any lookup for the Buyer and Seller output fields?'
       :'Would you like to update any lookup for the Buyer and Seller output fields?'
       }
       <div className="flex space-x-4 mt-6 justify-center">
          <button
            onClick={handleYes}
            className={`px-4 py-2 rounded bg-blue-600 text-white w-36`}
          >
            Yes
          </button>
          <button
            onClick={handleNo}
            className={`px-4 py-2 rounded bg-blue-600 text-white w-36`}
          >
            No
            {/* {t('forms.buttons.no')} */}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationLookupConfig;
