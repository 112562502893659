import { useEffect, useRef, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import UploadInvoice from "./steps/uploadInvoice/UploadInvoice";
import AddFields from "./steps/addFields/AddFields";
import Mapping from "./steps/mapping/Mapping";
import ConfirmConfigure from "./steps/finalConfirmation/ConfirmConfigure";
import FormatName from "./steps/FormatName";
import axios from "axios";
import { IconCheck } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../components/Toast";
import ConfirmationLookupConfig from "./steps/configureLookup/ConfirmLookUpConfig";
import ConfigureLookup from "./steps/configureLookup/ConfigureLookup";
import FormatConfirmation from "./steps/formatValidation/FormatConfirmation";
import FinalConiguration from "./steps/finalConfiguration/FinalConiguration";
import {
  ADD_DICTIONARY,
  ADD_FIELDS,
  ADD_FORMAT_HEADERS,
  MAPPING_FIELDS,
  MAPPING_FILE_STORED_PROC,
  RESOLVE_NAME_DICTIONARY,
  SETUP_LOOKUP_DETAILS,
  VALIDATE_FORMAT_NAME,
  VIEW_FIELDS,
} from "../../utils/api-details/ApiList";

interface BuyerSupplierData {
  supplierName?: string;
  buyerName?: string;
}
interface LookupDetails {
  url?: string;
  method?: string;
  username?: string;
  password?: string;
  requestBody?: {};
  pathParam?: {};
}
//steps for the stepper
const steps = [
  "Upload Invoice",
  "Format Name",
  "Add Fields",
  "Mapping Field",
  "Final Configuration",
  "Configure Lookup",
  "Confirm Configure",
];

// Map steps to components
const stepComponents = [
  UploadInvoice,
  FormatName,
  AddFields,
  Mapping,
  FinalConiguration,
  ConfigureLookup,
  ConfirmConfigure,
];

const StepperComponent = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [currentStep, setCurrentStep] = useState(0);
  const [formatName, setFormatName] = useState("");
  const [supportedOutput, setSupportedOutput] = useState<string[]>([]);
  const [isToggledForHeader, setIsToggledForHeader] = useState(false);
  const [fieldsList, setFieldsList] = useState<string[]>([]);
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [jsonFields, setJsonFields] = useState<string[]>([]);
  const [xmlFields, setXmlFields] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [csvFiles, setCsvFiles] = useState<File[]>([]);
  const [jsonFiles, setJsonFiles] = useState<File[]>([]);
  const [xmlFiles, setXmlFiles] = useState<File[]>([]);
  const [uploadInvoiceFiles, setUploadInvoiceFiles] = useState<File[]>([]);
  const [mapping, setMapping] = useState<{ [key: string]: string }>({});
  const [csvMapping, setCsvMapping] = useState<{ [key: string]: string }>({});
  const [jsonMapping, setJsonMapping] = useState<{ [key: string]: string }>({});
  const [xmlMapping, setXmlMapping] = useState<{ [key: string]: string }>({});
  const [hasChanges, setHasChanges] = useState(false);
  const [showNull, setShowNull] = useState("");
  const [value, setValue] = useState<string | null>(null);
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [isError, setIsError] = useState(false);
  const [outputFields, setOutputFields] = useState<any[]>([]);
  const [outputCsvFields, setOutputCsvFields] = useState<any[]>([]);
  const [outputJsonFields, setOutputJsonFields] = useState<any[]>([]);
  const [outputXmlFields, setOutputXmlFields] = useState<any[]>([]);
  const [fileExtractedData, setFileExtractedData] = useState({});
  // const [fileData, setFileData] = useState({});
  const [dictionaryMappingRequired, setDictionaryMappingRequired] =
    useState("");
  const navigate = useNavigate();
  const [buyerSupplier, setBuyerSupplier] = useState<BuyerSupplierData>({});
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { showToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [finalMappingRequried, setFinalMappingRequried] = useState<{
    [key: string]: string;
  }>({});
  const [finalCsvMappingRequried, setFinalCsvMappingRequried] = useState<{
    [key: string]: string;
  }>({});
  const [finalJsonMappingRequried, setFinalJsonMappingRequried] = useState<{
    [key: string]: string;
  }>({});
  const [finalXmlMappingRequried, setFinalXmlMappingRequried] = useState<{
    [key: string]: string;
  }>({});
  const [isLookupModalOpen, setIsLookupModalOpen] = useState(false);
  const [isConfigureLookup, setIsConfigureLookup] = useState(false);
  // const [buyerLookupDetails, setBuyerLookupDetails] = useState<LookupDetails>({});
  const [supplierLookupDetails, setSupplierLookupDetails] = useState<LookupDetails>({});
  const [activeTab, setActiveTab] = useState("buyer");
  // const [selectedLevel, setSelectedLevel] = useState("format_name");
  // const [selectedSetup, setSelectedSetup] = useState("dictionary");
  const [dictionaryObject, setDictionaryObject] = useState("");
  const setup_Lookup_details = SETUP_LOOKUP_DETAILS;
  const resolve_name_dic = RESOLVE_NAME_DICTIONARY;
  const validate_format_name = VALIDATE_FORMAT_NAME;
  const mapping_file_stored_proc = MAPPING_FILE_STORED_PROC;
  const add_format_headers = ADD_FORMAT_HEADERS;
  const add_dictionary = ADD_DICTIONARY;
  const add_fields = ADD_FIELDS;
  const view_fields = VIEW_FIELDS;
  const mapping_fields = MAPPING_FIELDS;
  useEffect(() => {
    const handleOverflow = () => {
      if (contentRef.current) {
        setIsOverflowing(
          contentRef.current.scrollHeight > contentRef.current.clientHeight
        );
      }
    };

    handleOverflow();
    window.addEventListener("resize", handleOverflow);

    return () => {
      window.removeEventListener("resize", handleOverflow);
    };
  }, []);

  const handleNext = async () => {
    const apiSteps = [1, 2, 3, 4]; // Steps requiring API calls
    // const apiSteps = [1, 2, 3, 4, 5]; // Steps requiring API calls
    if (apiSteps.includes(currentStep)) {
      await apiCallForStep(currentStep);
    }
    if (currentStep + 1 === 5) {
      setIsLookupModalOpen(true);
    }
    setCurrentStep(currentStep + 1);
    setIsError(false);
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      if (!isConfigureLookup) {
        if (currentStep - 1 === 5) {
          setCurrentStep(4);
        }
      }
    }
  };

  const handleOk = () => {
    // console.log("Configuration confirmed:", {formatName,supportedOutput,isToggledForHeader,});
    // closeConfigureModal();
    navigate("/dashboard");
    // setSelectedLevel("");
    // setSelectedSetup("");
  };

  const CurrentStepComponent = stepComponents[currentStep];

  const handleAddDict = async () => {
    const payload = {
      "BASIC FREIGHT": "BASIC FREIGHT",
      "Bunker surcharge NOS": "BAF NOS",
      "Bunker Recovery Adjustment Factor": "BUNKER RECOVERY",
      "Open top additional": "OPEN TOP ADD",
      "Flat rack additional": "FLAT RACK ADD",
      "Terminal handl ch origin": "THC ORIG",
      "Sealing service export": "SEALING EXPORT",
      "Export Documentation Fee": "EXPORT DOC FEE",
      "Doc Fee Special Equipment and/or Dangerous Cargo": "DOC FEE SPEC",
      "Container maintenance charge at origin": "CTR MAINT ORIG",
      "Export Declaration Surcharge": "EXP DECLARATION",
      "Ocean Carrier-Intl Ship & port Facility Security": "ISPS VESSEL",
      "Sensitive Cargo Contribution": "SENSITIVE CONTR",
      "Late Shipping Instruction submission": "LATE SI SUBMISS",
      "Terminal handl. ch destination": "THC DEST",
      "Cargo facility charge": "C. FACILITY CH",
      "Destinat.Terminal-Intl Ship&Port facility Security": "ISPS TERM DEST",
      "Bill of Lading Amendment Fee": "BL AMENDMENT",
      "Invoice Amendment": "INV AMENDMENT",
      "Cargo Value Serenity 2": "SERENITY 2",
    };
    await axios
      .post(`${apiUrl + add_dictionary}`, payload, {
        params: {
          org_id: orgID,
          user_id: userID,
          format_name: formatName,
        },
      })
      .then((response) => {
        console.log("response.data", response?.data?.data?.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFormatValidation = async () => {
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
      format_name: formatName,
    };
    await axios
      .post(`${apiUrl + validate_format_name}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log("response.data", response?.data?.data);
        const isExit = response?.data?.data?.HFVALIDATION_FLAG;
        const msg = response?.data?.data?.HFERROR_MSG;
        if (isExit === 1) {
          showToast(msg, "error");
          setIsOpen(true);
        }
        // addFieldsAPI()
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addFieldsAPI = async () => {
    handleAddDict();
    const formatType = supportedOutput.join(",");
    const fields = fieldsList.join(",");
    const formData = new FormData();
    // Append files to formData
    files.forEach((file) => {
      formData.append("file", file);
    });
    // Append fields
    formData.append("fields", fieldsList.length > 0 ? fields : "");
    formData.append("format_type", formatType);
    formData.append("format_name", formatName);
    formData.append("orgid", orgID ?? "");
    formData.append("user_id", userID ?? "");

    if (isToggledForHeader) {
      try {
        const response = await axios.post(`${apiUrl + add_fields}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response from addFieldsAPI:", response);
        viewFieldsAPI();
      } catch (error) {
        console.error("Error in addFieldsAPI:", error);
      }
    } else {
      for (const type of supportedOutput) {
        const formatData = new FormData();
        switch (type) {
          case "json":
            jsonFiles.forEach((file) => {
              formatData.append("file", file);
            });
            const jsonFieldsString =
              jsonFields.length > 0 ? jsonFields.join(",") : "";
            formatData.append("fields", jsonFieldsString);
            break;
          case "csv":
            csvFiles.forEach((file) => {
              formatData.append("file", file);
            });
            const csvFieldsString =
              csvFields.length > 0 ? csvFields.join(",") : "";
            formatData.append("fields", csvFieldsString);
            break;
          case "xml":
            xmlFiles.forEach((file) => {
              formatData.append("file", file);
            });
            const xmlFieldsString =
              xmlFields.length > 0 ? xmlFields.join(",") : "";
            formatData.append("fields", xmlFieldsString);
            break;
          default:
            break;
        }
        formatData.append("format_type", type);
        formatData.append("format_name", formatName);
        formatData.append("orgid", orgID ?? "");
        formatData.append("user_id", userID ?? "");
        try {
          const response = await axios.post(
            `${apiUrl + add_fields}`,
            formatData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log(`Response for ${type} format:`, response);
          viewFieldsAPI();
        } catch (error) {
          console.error(`Error in addFieldsAPI for ${type}:`, error);
        }
      }
    }
  };

  const viewFieldsAPI = async () => {
    try {
      for (const formatType of supportedOutput) {
        const response = await axios.get(`${apiUrl + view_fields}`, {
          params: {
            format_type: formatType,
            format_name: formatName,
            orgid: orgID,
            user_id: userID,
          },
        });
        console.log("Response from viewFieldsAPI:", response);
        const fields = response?.data?.fields;
        // if (formatType === "csv") {
        //   setOutputCsvFields(fields);
        //   console.log(outputCsvFields);
        // }
        // if (formatType === "json") {
        //   setOutputJsonFields(fields);
        // }
        // if (formatType === "xml") {
        //   setOutputXmlFields(fields);
        // }
        // if (isToggledForHeader) {
        //   setOutputFields(fields);
        // }
        if (formatType === "csv") {
          setOutputCsvFields(fields);
        } else if (formatType === "json") {
          setOutputJsonFields(fields);
        } else if (formatType === "xml") {
          setOutputXmlFields(fields);
        }
        if (isToggledForHeader) {
          setOutputFields(fields);
        }
      }
    } catch (error) {
      console.error("Error in viewFieldsAPI:", error);
    }
  };

  const mappingFieldsAPI = async () => {
    if (isToggledForHeader) {
      const updatedMapping = { ...mapping };
      outputFields.forEach((field) => {
        if (!(field in updatedMapping)) {
          updatedMapping[field] = "";
        }
      });
      const formatType = supportedOutput.join(",");
      const payload = {
        data_dict: updatedMapping,
        data_setting_dict: {
          skip_null: showNull === "Yes" ? "True" : "False",
          alias: showNull === "Yes" ? value : "-",
          dictionary_mapping_required: dictionaryMappingRequired,
        },
      };
      try {
        const response = await axios.post(
          `${apiUrl + mapping_fields}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              format_type: formatType,
              format_name: formatName,
              org_id: orgID,
              user_id: userID,
            },
          }
        );
        console.log("Response from mappingFieldsAPI:", response);
        // mappingStore(formatType);
        // mappingStore(formatType,updatedMapping);
      } catch (error) {
        console.error("Error in mappingFieldsAPI:", error);
      }
      setMapping({});
    } else {
      for (const formatType of supportedOutput) {
        let payload;
        switch (formatType.toLowerCase()) {
          case "csv":
            const updatedCsvMapping = { ...csvMapping };
            Object.keys(updatedCsvMapping).forEach((key) => {
              if (!outputCsvFields.includes(key)) {
                delete updatedCsvMapping[key];
              }
            });
            outputCsvFields.forEach((outputField) => {
              if (!(outputField in updatedCsvMapping)) {
                updatedCsvMapping[outputField] = "";
              }
            });

            payload = {
              data_dict: updatedCsvMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            // mappingStore(formatType,updatedCsvMapping);

            break;
          case "json":
            const updatedJsonMapping = { ...jsonMapping };
            Object.keys(updatedJsonMapping).forEach((key) => {
              if (!outputJsonFields.includes(key)) {
                delete updatedJsonMapping[key];
              }
            });
            outputJsonFields.forEach((outputField) => {
              if (!(outputField in updatedJsonMapping)) {
                updatedJsonMapping[outputField] = "";
              }
            });
            payload = {
              data_dict: updatedJsonMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            // mappingStore(formatType,updatedJsonMapping);

            break;
          case "xml":
            const updatedXmlMapping = { ...xmlMapping };
            Object.keys(updatedXmlMapping).forEach((key) => {
              if (!outputXmlFields.includes(key)) {
                delete updatedXmlMapping[key];
              }
            });
            outputXmlFields.forEach((outputField) => {
              if (!(outputField in updatedXmlMapping)) {
                updatedXmlMapping[outputField] = "";
              }
            });
            payload = {
              data_dict: updatedXmlMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            // mappingStore(formatType,updatedXmlMapping);
            break;
          default:
            break;
        }
        try {
          const response = await axios.post(
            `${apiUrl + mapping_fields}`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                format_type: formatType,
                format_name: formatName,
                org_id: orgID,
                user_id: userID,
              },
            }
          );
          console.log(`Response from mappingFieldsAPI:`, response);
          // mappingStore(formatType);
        } catch (error) {
          console.error(`Error in mappingFieldsAPI for ${formatType}:`, error);
          showToast("Something went wrong", "error");
        }
      }
      // setCsvMapping({})
      // setJsonMapping({})
      // setXmlMapping({})
    }
    formatHeaders();
  };

  // const mappingStores = async () => {
  //   const formatType = supportedOutput.join(",");
  //   await axios
  //     // .post(`${apiUrl}/mapping_file_stored_proc`, payload, {
  //     .post(`${apiUrl}/mapping_file_stored_proc`, finalMappingRequried, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       params: {
  //         orgid: orgID,
  //         user_id: userID,
  //         format_name: formatName,
  //         format_type: formatType,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("response.data", response?.data?.data?.results);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const mappingStore = async () => {
    if (isToggledForHeader) {
      const formatType = supportedOutput.join(",");
      try {
        const response = await axios.post(
          `${apiUrl + mapping_file_stored_proc}`,
          finalMappingRequried,
          {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              orgid: orgID,
              user_id: userID,
              format_name: formatName,
              format_type: formatType,
            },
          }
        );
        console.log("Response from mappingFieldsAPI:", response);
      } catch (error) {
        console.error("Error in mappingFieldsAPI:", error);
      }
      setFinalMappingRequried({});
    } else {
      for (const formatType of supportedOutput) {
        let payload;
        switch (formatType.toLowerCase()) {
          case "csv":
            payload = finalCsvMappingRequried;
            break;
          case "json":
            payload = finalJsonMappingRequried;
            break;
          case "xml":
            payload = finalXmlMappingRequried;
            break;
          default:
            break;
        }
        try {
          await axios.post(`${apiUrl + mapping_file_stored_proc}`, payload, {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              orgid: orgID,
              user_id: userID,
              format_name: formatName,
              format_type: formatType,
            },
          });
          setFinalCsvMappingRequried({});
          setFinalJsonMappingRequried({});
          setFinalXmlMappingRequried({});
        } catch (error) {
          console.error(`Error in mappingFieldsAPI for ${formatType}:`, error);
        }
      }
    }
  };

  const formatHeaders = async () => {
    const payload = {
      headers: fileExtractedData,
    };
    await axios
      .post(`${apiUrl + add_format_headers}`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          org_id: orgID,
          user_id: userID,
          format_name: formatName,
        },
      })
      .then((response) => {
        console.log("response.data", response?.data?.data?.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

//   const handleLookupDetails = () => {
//     console.log("data", buyerLookupDetails);
//     console.log("data supplierLookupDetails", supplierLookupDetails);

//     console.log("dic", dictionaryObject);
//     const formatType = supportedOutput.join(",");
//     // const dicPayload = dictionaryObject;
//     axios
//       .post(`${apiUrl + resolve_name_dic}`, dictionaryObject, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         params: {
//           org_id: orgID,
//           format_name: formatName,
//           output_format_types: formatType,
//           save_level: selectedLevel,
//           type: activeTab,
//         },
//       })
//       .then((response) => {
//         console.log("response", response);
//       })
//       .catch(function (error) {
//         console.log(error);
//         showToast("Something went wrong", "error");
//       });

// console.log("buyerLookupDetails",Object.keys(buyerLookupDetails).length)
// console.log("supplierLookupDetails",Object.keys(supplierLookupDetails).length)

// if(Object.keys(buyerLookupDetails).length !== 0){
//   const payload = {
//     // field:
//     //   activeTab === "buyer"
//     //     ? buyerSupplier?.buyerName
//     //     : buyerSupplier?.supplierName,
//     field: buyerSupplier?.buyerName,
//     isLookupEnabled: isConfigureLookup,
//     lookupType: selectedSetup === "Api" ? "API" : "Dictionary",
//     ...(selectedSetup === "Api" && {
//       apiSetup: {
//         url: buyerLookupDetails?.url,
//         method: buyerLookupDetails?.method,
//         requestBody: buyerLookupDetails?.requestBody,
//         request_parameters: {
//           org_id: orgID,
//           user_id: userID,
//         },
//         pathParam: buyerLookupDetails?.pathParam,
//         credentials: {
//           username: buyerLookupDetails?.username,
//           password: buyerLookupDetails?.password,
//         },
//         returnType: "JSON",
//       },
//     }),
//   };
//   axios
//   .post(`${apiUrl + setup_Lookup_details}`, payload, {
//     params: {
//       format_type: formatType,
//       format_name: formatName,
//       file_prefix: activeTab,
//       org_id: orgID,
//       user_id: userID,
//     },
//   })
//   .then((response) => {
//     console.log("response", response);
//   })
//   .catch(function (error) {
//     console.log("error",error);
//     // console.log("error",error?.data?.message);
//     showToast("Something went wrong", "error");
//   });
// }

// if(Object.keys(supplierLookupDetails).length !== 0){

//     const payload = {
//       // field:
//       //   activeTab === "buyer"
//       //     ? buyerSupplier?.buyerName
//       //     : buyerSupplier?.supplierName,
//       field: buyerSupplier?.supplierName,
//       isLookupEnabled: isConfigureLookup,
//       lookupType: selectedSetup === "Api" ? "API" : "Dictionary",
//       ...(selectedSetup === "Api" && {
//         apiSetup: {
//           url: buyerLookupDetails?.url,
//           method: buyerLookupDetails?.method,
//           requestBody: buyerLookupDetails?.requestBody,
//           request_parameters: {
//             org_id: orgID,
//             user_id: userID,
//           },
//           pathParam: buyerLookupDetails?.pathParam,
//           credentials: {
//             username: buyerLookupDetails?.username,
//             password: buyerLookupDetails?.password,
//           },
//           returnType: "JSON",
//         },
//       }),
//     };
//     axios
//       .post(`${apiUrl + setup_Lookup_details}`, payload, {
//         params: {
//           format_type: formatType,
//           format_name: formatName,
//           file_prefix: activeTab,
//           org_id: orgID,
//           user_id: userID,
//         },
//       })
//       .then((response) => {
//         console.log("response", response);
//       })
//       .catch(function (error) {
//         console.log("error",error);
//         // console.log("error",error?.data?.message);
//         showToast("Something went wrong", "error");
//       });
//     }
//   };

  const apiCallForStep = async (stepIndex: number) => {
    switch (stepIndex) {
      case 1:
        return handleFormatValidation();
      case 2:
        return addFieldsAPI();
      case 3:
        return mappingFieldsAPI();
      case 4:
        return mappingStore();
      // case 5:
      //   return handleLookupDetails();
      default:
        throw new Error("No API defined for this step.");
    }
  };

  const handleCloseFormatConfirmation = () => {
    setIsOpen(false);
  };

  const handleRename = () => {
    setCurrentStep(currentStep - 1);
    setIsOpen(false);
  };

  const handleCloseLookup = () => {
    setIsLookupModalOpen(false);
  };

  return (
    <div>
      <div className="w-full p-4 border-2 border-gray-300 rounded-lg bg-grey-50  flex flex-col items-center pb-10">
        {/* <div className="w-full p-4 flex flex-col items-center pb-10"> */}
        <div className="w-full px-12">
          <div className="p-0 ">
            <Stepper activeStep={currentStep}>
              {steps.map((step, index) => {
                const isActive = index === currentStep;
                const isCompleted = index < currentStep;
                return (
                  <Step key={index} label={step}>
                    <div className={`${isCompleted ? "bg-blue-500 text-white" : ""}
                                ${isActive ? "bg-blue-200  border-2 border-blue-500 text-black" : ""}
                    ${!isActive && !isCompleted ? "bg-gray-300 text-gray-600" : "" }
                    w-8 h-8 rounded-full flex justify-center items-center
                    text-sm font-semibold
                  `}>
                      {isCompleted ? (
                        <IconCheck
                          stroke={2}
                          color="white"
                          className="text-xl"
                        />
                      ) : (
                        <span className="text-base">{index + 1}</span>
                      )}
                    </div>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <div
            // className={`min-h-96 max-h-[800vh]  p-4 border-2 border-gray-300 rounded-lg bg-sky-50`}
            className={`min-h-96 max-h-auto  p-4 border-2 border-gray-300 rounded-lg bg-sky-50`}
          >
            <CurrentStepComponent
              setFormatName={setFormatName}
              setSupportedOutput={setSupportedOutput}
              formatName={formatName}
              supportedOutput={supportedOutput}
              setIsToggledForHeader={setIsToggledForHeader}
              isToggledForHeader={isToggledForHeader}
              fieldsList={fieldsList}
              setFieldsList={setFieldsList}
              files={files}
              setFiles={setFiles}
              uploadInvoicefiles={uploadInvoiceFiles}
              setUploadInvoiceFiles={setUploadInvoiceFiles}
              setMapping={setMapping}
              mapping={mapping}
              outputFields={outputFields}
              setHasChanges={setHasChanges}
              showNull={showNull}
              setShowNull={setShowNull}
              value={value}
              setValue={setValue}
              setCsvFiles={setCsvFiles}
              csvFiles={csvFiles}
              setJsonFiles={setJsonFiles}
              jsonFiles={jsonFiles}
              setXmlFiles={setXmlFiles}
              xmlFiles={xmlFiles}
              csvFields={csvFields}
              setCsvFields={setCsvFields}
              jsonFields={jsonFields}
              setJsonFields={setJsonFields}
              xmlFields={xmlFields}
              setXmlFields={setXmlFields}
              isError={isError}
              outputCsvFields={outputCsvFields}
              outputJsonFields={outputJsonFields}
              outputXmlFields={outputXmlFields}
              csvMapping={csvMapping}
              setCsvMapping={setCsvMapping}
              jsonMapping={jsonMapping}
              setJsonMapping={setJsonMapping}
              xmlMapping={xmlMapping}
              setXmlMapping={setXmlMapping}
              fileExtractedData={fileExtractedData}
              setFileExtractedData={setFileExtractedData}
              // fileData= {fileData}
              // setFileData={setFileData}
              setDictionaryMappingRequired={setDictionaryMappingRequired}
              buyerSupplier={buyerSupplier}
              setBuyerSupplier={setBuyerSupplier}
              setFinalMappingRequried={setFinalMappingRequried}
              setFinalCsvMappingRequried={setFinalCsvMappingRequried}
              setFinalJsonMappingRequried={setFinalJsonMappingRequried}
              setFinalXmlMappingRequried={setFinalXmlMappingRequried}
              finalMappingRequried={finalMappingRequried}
              isCreate={true}
              // setBuyerLookupDetails={setBuyerLookupDetails}
              isConfigureLookup={isConfigureLookup}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              // setSelectedSetup={setSelectedSetup}
              // selectedSetup={selectedSetup}
              // setSelectedLevel={setSelectedLevel}
              // selectedLevel={selectedLevel}
              setDictionaryObject={setDictionaryObject}
              dictionaryObject={dictionaryObject}
              // setSupplierLookupDetails={setSupplierLookupDetails}
            />
          </div>
        </div>
        <div className="flex space-x-4 mt-6">
          <button
            onClick={handlePrev}
            disabled={currentStep === 0}
            className={`px-4 py-2 rounded ${
              currentStep === 0
                ? "bg-gray-300 text-gray-500"
                : "bg-blue-600 text-white"
            }`}
          >
            Previous
          </button>

          <button
            onClick={currentStep === steps.length - 1 ? handleOk : handleNext}
            disabled={currentStep === steps.length - 1 && !formatName}
            className={`px-4 py-2 rounded ${
              currentStep === steps.length - 1
                ? "bg-blue-600 text-white"
                : "bg-blue-600 text-white"
            }`}
          >
            {currentStep === steps.length - 1 ? "OK" : "Save & Continue"}
          </button>
        </div>
      </div>
      {isOpen && (
        <FormatConfirmation
          open={isOpen}
          onClose={handleCloseFormatConfirmation}
          handleRename={handleRename}
        />
      )}
      {isLookupModalOpen && (
        <ConfirmationLookupConfig
          open={isLookupModalOpen}
          onClose={handleCloseLookup}
          setIsConfigureLookup={setIsConfigureLookup}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}
    </div>
  );
};

export default StepperComponent;
