import Modal from "../../components/Modal";

 const UploadingProcess = ({isModalOpen,closeModal,progress,handleCancel}:{isModalOpen: boolean,closeModal:() => void,progress: number,handleCancel:()=>void}) => {

    return (
        <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Upload PDF"
        showCancelButton={false}
        size="w-5/12"
      >
        <div
          className={`w-full max-w-2xl  bg-sky-50 p-4 rounded-lg border-2 border-gray-300}`}
        >
          <div className="mt-10 flex flex-col items-center mb-2">
            <span className="mb-1 text-xs font-semibold text-gray-600">
              {progress}% Completed
            </span>
            <div className="relative flex-grow w-full bg-gray-300 rounded-full h-2.5 mt-1">
              <div
                className="absolute top-0 left-0 bg-blue-500 h-2.5 rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <span className="text-xs font-semibold text-gray-600 mt-2">
              Uploading File...
            </span>
          </div>
          <div className="mb-10 flex justify-center">
            <button
              type="button"
              onClick={handleCancel}
              className="mt-8 px-4 py-2 w-44 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
  );
}

export default UploadingProcess;