import React, { useState } from "react";
import DataTable from "react-data-table-component";

type DataTableProps<T> = {
  title: string;
  columns: {
    name: string;
    selector?: (row: T) => any;
    sortable?: boolean;
    filterable?: boolean;
  }[];
  rows: T[];
  selectableRows?: boolean;
  onSelectedRowsChange?: (state: any) => void;
  pagination?: boolean;
  highlightOnHover?: boolean;
  striped?: boolean;
  persistTableHead?: boolean;
};

const TableComponent = <T extends {}>({
  title,
  columns,
  rows,
  selectableRows = false,
  onSelectedRowsChange,
  pagination = true,
  highlightOnHover = true,
  striped = true,
  persistTableHead = true,
}: DataTableProps<T>) => {
  const [filter, setFilter] = useState<string>("");
  const [columnFilters, setColumnFilters] = useState<{ [key: string]: string }>(
    {}
  );

  const filteredRows = rows.filter((row) => {
    const matchesGlobalFilter = filter
      ? columns.some((column) => {
          const cellValue = column.selector ? column.selector(row) : "";
          if (typeof cellValue === "string") {
            return cellValue.toLowerCase().includes(filter.toLowerCase());
          } else if (typeof cellValue === "number") {
            return cellValue.toString().includes(filter);
          }
          return false;
        })
      : true;

    return matchesGlobalFilter;
  });

  // Handle changes in the global filter
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const hasFilterableColumns = columns.some((column) => column.filterable);

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-xl font-semibold text-gray-800 mb-6">{title}</h1>

      {/* Global filter input */}
      {hasFilterableColumns && (
        <div className="mb-6">
          <input
            type="text"
            className="w-96 p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Search..."
            value={filter}
            onChange={handleFilterChange}
          />
        </div>
      )}

      <div className="overflow-x-auto rounded-lg shadow-lg">
        <DataTable
          columns={columns}
          data={filteredRows}
          selectableRows={selectableRows}
          onSelectedRowsChange={onSelectedRowsChange}
          pagination={pagination}
          highlightOnHover={highlightOnHover}
          striped={striped}
          persistTableHead={persistTableHead}
          className="min-w-full bg-white border border-gray-200 rounded-lg"
        />
      </div>
    </div>
  );
};

export default TableComponent;
