import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { USER_LOGIN } from "../utils/api-details/ApiList";
interface AuthContextType {
  isAuthenticated: boolean;
  login: (email: string, password: string, orgid: string) => Promise<boolean>;
  logout: () => void;
  errorMessage: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const savedState = sessionStorage.getItem("isAuthenticated");
    return savedState ? JSON.parse(savedState) : false;
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const user_login = USER_LOGIN;

  const login = async (
    email: string,
    password: string,
    orgid: string
  ): Promise<boolean> => {
    const credentials = { username: email, password: password, orgid: orgid };

    try {
      const response = await axios.post(`${apiUrl + user_login}`, credentials, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      console.log("Login response:", response);

      if (response.status === 200) {
        const { user_type, user_id, org_id, reset_complete, username } =
          response.data;
        // Store specific values in sessionStorage
        sessionStorage.setItem("isAuthenticated", "true");
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("userId", user_id);
        sessionStorage.setItem("orgId", org_id);
        sessionStorage.setItem("user_type", user_type);
        sessionStorage.setItem("reset_complete", reset_complete);
        // store the entire response object in sessionStorage
        // sessionStorage.setItem("userData", JSON.stringify(response.data));
        setIsAuthenticated(true);
        setErrorMessage(null);
        return true;
      }
    } catch (error: any) {
      console.error("Login failed:", error);
      setErrorMessage(error.response?.data?.message || "Invalid credentials");
      return false;
    }
    return false;
  };

  const logout = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem("isAuthenticated");
    sessionStorage.removeItem("authToken");
    setErrorMessage(null);
  };

  useEffect(() => {
    const savedState = sessionStorage.getItem("isAuthenticated");
    if (savedState) {
      setIsAuthenticated(JSON.parse(savedState));
    }
  }, []);

  const value = {
    isAuthenticated,
    login,
    logout,
    errorMessage, 
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
