import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonForm from './en/common/commonForm.json';
import dashboardForm from './en/dashboard/dashboardForm.json';

export const defaultNS = 'commonForm';

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: true,
  resources: {
    en: {
      commonForm,
      dashboardForm,
    },
  },
  defaultNS,
});

export default i18next; 
