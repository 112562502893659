import { useEffect } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

interface InvoiceListProps {
  setFormatName: (name: string) => void;
  formatName: string;
  setHasChanges: (value: boolean) => void;
  selectedFormat: string[];
}

const InvoiceList: React.FC<InvoiceListProps> = ({
  setFormatName,
  formatName,
  setHasChanges,
  selectedFormat,
}) => {
  const handleChange = (selectedOption: { value: string }) => {
    setFormatName(selectedOption.value);
    setHasChanges(true);
  };

  useEffect(() => {
    if (formatName !== "") {
      setHasChanges(true);
    }
  }, [formatName, setHasChanges]);

  const options = selectedFormat
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((invoice) => ({ value: invoice, label: invoice }));

  return (
    <div className="flex flex-col items-center justify-center mt-16">
      <div className="flex justify-between items-center">
        <label htmlFor="format-select" className="flex-none pl-4 font-medium">
          Select the Format
        </label>
        <div className="flex-1 pr-4 ml-4 mt-2">
          <Dropdown
            options={options}
            onChange={handleChange}
            value={formatName}
            placeholder="Select a Format"
            className=" p-1 w-96"
            controlClassName="react-dropdown-control"
            menuClassName="react-dropdown-menu max-h-48 overflow-y-auto"
            // disabled={selectedFormat.length === 0}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceList;
