import { useEffect, useState } from "react";

import axios from "axios";
import { useToast } from "../../../components/Toast";
import Header from "../../dashboard/Header";
import RadioGroupComponent from "../../../components/RadioGroupComponent";
import TableComponent from "../../../components/TableComponent";
import {
  GET_INVOICE_AMOUNT_BY_FORMAT_NAME,
  GET_INVOICE_AMOUNT_BY_ORGID,
  GET_INVOICE_AMOUNT_BY_VENDOR_NAME,
} from "../../../utils/api-details/ApiList";

type StatsData = {
  FVENDOR_NAME: string;
  FORMAT_NAME: string;
  Total_taxable_amount: number;
  Total_tax_amount: number;
  Total_invoice_amount: number;
};

const Stats = () => {
  const { showToast } = useToast();
  const [selectedLevel, setSelectedLevel] = useState("Vendor");
  const apiUrl = process.env.REACT_APP_API_URL;
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [data, setData] = useState<StatsData[]>([]);
  const get_invoice_amount_by_vendor_name = GET_INVOICE_AMOUNT_BY_VENDOR_NAME;
  const get_invoice_amount_by_orgid = GET_INVOICE_AMOUNT_BY_ORGID;
  const get_invoice_amount_by_format_name = GET_INVOICE_AMOUNT_BY_FORMAT_NAME;
  // const levels = ["Vendor", "Format", "Org"];
 
  const levels = [
    { Label: "Vendor", value: "Vendor" },
    { Label: "Format", value: "Format" },
    { Label: "Org", value: "Org" },
  ];
  useEffect(() => {
    getVendorDetails(get_invoice_amount_by_vendor_name);
  }, []);

  const columns = [
    {
      name: "Vender Name",
      selector: (row: StatsData) => row.FVENDOR_NAME,
      sortable: true,
      filterable: true,
      //   style: {
      //     minWidth: "600px",
      //     maxWidth: "600px",
      //   },
    },
    {
      name: "Format Name",
      selector: (row: StatsData) => row.FORMAT_NAME,
      sortable: true,
      filterable: true,
      //   style: {
      //     minWidth: "200px",
      //     maxWidth: "300px",
      //   },
    },
    {
      name: "Total Taxable Amount",
      selector: (row: StatsData) => row.Total_taxable_amount,
      sortable: true,
    },
    {
      name: "Total Tax Amount",
      selector: (row: StatsData) => row.Total_tax_amount,
      sortable: true,
    },
    {
      name: "Total Invoice Amount",
      selector: (row: StatsData) => row.Total_invoice_amount,
      sortable: false,
    },
  ];

  const handleLevelChange = (value: string) => {
    setSelectedLevel(value);
    handleAction(value);
  };

  const handleAction = (level: string) => {
    if (level === "Org") {
      getVendorDetails(get_invoice_amount_by_orgid);
    } else if (level === "Vendor") {
      getVendorDetails(get_invoice_amount_by_vendor_name);
    } else if (level === "Format") {
      getVendorDetails(get_invoice_amount_by_format_name);
    }
  };

  const getVendorDetails = (type: string) => {
    axios
      .get(`${apiUrl + type}`, {
        params: {
          org_id: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        console.log("response", response?.data?.data?.data?.results);
        setData(response?.data?.data?.data?.results);
      })
      .catch((error) => {
        console.log(error);
        showToast("Something went wrong!", "error");
      });
  };

  // filter columns based on the presence of data
  const dynamicColumns = columns.filter((column) => {
    if (column.name === "Vender Name" && data.some((row) => row.FVENDOR_NAME)) {
      return true;
    }
    if (column.name === "Format Name" && data.some((row) => row.FORMAT_NAME)) {
      return true;
    }
    if (column.name !== "Vender Name" && column.name !== "Format Name") {
      return true;
    }
    return false;
  });

  return (
    <div>
      <Header />
      <div className="px-4 ">
        <div className="bg-blue-50 shadow-lg rounded-lg p-4 mb-4 card min-h-[33rem] h-auto">
          <h3 className=" text-xl font-bold font-Inter mb-4 text-blue-700">
            Select your perferred statistics
          </h3>
          <RadioGroupComponent
            selectedValue={selectedLevel}
            handleValueChange={handleLevelChange}
            levels={levels}
            />
          <div className="overflow-x-auto">
            <TableComponent
              title={selectedLevel}
              columns={dynamicColumns}
              rows={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
